import { useFeatureIsOn } from '@growthbook/growthbook-react'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Spin } from 'antd'
import { SiderLayout } from '../../../core/layouts'
import { DashboardGeneral, DashboardHome, InitialScreen } from './components'
import { sendUserDataToGTM } from 'modules/core/utils'
// import { ROLES } from 'modules/core/constants';
import configuration from 'modules/configuration'
import planning from 'modules/planning'
import login from 'modules/login'
// import { getOperationsByKeys, isUserAllowed } from 'modules/core/utils';

// const { PLANNING, CONTROL, FORECAST, ANALYSIS, SETTINGS } = ROLES;

import './Home.scss'

export const FRONT_URL = process.env.REACT_APP_FRONT_URL || 'https://next.stg.getplika.com'

const Home = ({ dimensionsList, fetchDimensionsList, fetchPeriodList, loggedUser, periodList }) => {
  const [isConfigLoading, setIsConfigLoading] = useState(false)
  const enabledHome = useFeatureIsOn('feature-dashbard-home')
  const enabledIntegrations = useFeatureIsOn('feature-dashbard-integrations')

  useEffect(() => {
    setIsConfigLoading(true)
    fetchDimensionsList()
      .then(() => setIsConfigLoading(false))
      .catch(() => setIsConfigLoading(false))

    return () => setIsConfigLoading(false)
  }, [fetchDimensionsList])

  useEffect(() => {
    fetchPeriodList()
  }, [fetchPeriodList])

  useEffect(() => {
    sendUserDataToGTM(loggedUser, '/')
  }, [loggedUser])

  const canUserViewDashboard = loggedUser.roles.find(
    (el) => el.code === 'adm' && el.name === 'Admin',
  )

  const renderDash = () => {
    if (!enabledHome) {
      return <DashboardHome />
    }

    return (
      <SiderLayout className={'dashboard-home-iframe'}>
        <iframe
          id="DashboardhHome"
          title="DashboardhHome"
          src={`${FRONT_URL}/dashboard`}
          width="100%"
          height="100%"
          style={{
            border: 'none',
            padding: '0 0',
            overflowX: 'hidden',
            overflowY: 'auto',
          }}
        ></iframe>
      </SiderLayout>
    )
  }

  const renderIntegration = () => {
    if (!enabledIntegrations) {
      return <InitialScreen />
    }

    return (
      <div
        className={'sider-layout dashboard-home-iframe'}
        style={{
          width: '100%',
          height: window.innerHeight - 56,
        }}
      >
        <iframe
          id="DashboardIntegrations"
          title="DashboardIntegrations"
          src={`${FRONT_URL}/integrations/inject`}
          width="100%"
          height="100%"
          style={{
            border: 'none',
            padding: '0 0',
            overflowX: 'hidden',
            overflowY: 'auto',
          }}
        ></iframe>
      </div>
    )
  }

  return (
    <>
      <Spin style={{ paddingTop: 20 }} spinning={isConfigLoading} />
      {!isConfigLoading &&
        (dimensionsList.count <= 0 || (dimensionsList.count > 0 && periodList.length <= 0)) &&
        renderIntegration()}
      {!isConfigLoading &&
        dimensionsList.count > 0 &&
        periodList.length > 0 &&
        (canUserViewDashboard ? (
          renderDash()
        ) : (
          <DashboardGeneral hasDataConfig={dimensionsList.count > 0} />
        ))}
    </>
  )
}

const mapStateToProps = (state) => ({
  dimensionsList: configuration.selectors.getDimensionsList(state),
  periodList: planning.selectors.getPeriodList(state),
  loggedUser: login.selectors.getWhoAmI(state),
})

const mapDispatchToProps = {
  fetchDimensionsList: configuration.actions.fetchDimensionsList,
  fetchPeriodList: planning.actions.fetchPeriodList,
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
