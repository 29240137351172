const general = {
  HI: '¡Hola, {{name}}!',
  ES: 'Español',
  EN: 'English',
  YES: 'Si',
  NO: 'No',

  // Layout
  PROFILE: 'Mi perfil',
  SESSION: 'Sesión',
  MENU: 'Menú',
  LOGIN: 'iniciar sesión',
  SIGN_OFF: 'Cerrar sesión',
  SIGN_OFF_CONFIRM_MESSAGE:
    '¿Tu día de trabajo terminó? Ok. Hora de descansar. Si estás seguro que deseas cerrar sesión, nos resta decirte ¡Hasta pronto!',
  NO_NOTIFICATIONS_MESSAGE: 'No hay nuevas notificaciones.',

  NOT_FOUND_SCREEN_TITLE: 'Está página no esta disponible',
  NOT_FOUND_SCREEN_DESCRIPTION:
    'El link que ingresaste puede que este roto o la página haya sido removida.',

  // Call to actions
  ACTION_ACEPT: 'Aceptar',
  ACTION_ACTIVITIES: 'Ver actividades',
  ACTION_ADJUST: 'Ajustar',
  ACTION_ANULATE: 'Anular',
  ACTION_APPLY: 'Aplicar',
  ACTION_APPROVE: 'Aprobar',
  ACTION_CANCEL: 'Cancelar',
  ACTION_CLEAR: 'Borrar',
  ACTION_CLOSE: 'Cerrar',
  ACTION_CONFIGURATE: 'Configurar',
  ACTION_COPY: 'Crear copia',
  ACTION_CREATE: 'Crear',
  ACTION_CREATE_NEW: 'Crear nuevo',
  ACTION_DEFINE: 'Definir',
  ACTION_DELETE: 'Eliminar',
  ACTION_DELETE_ROW: 'Eliminar fila',
  ACTION_DELETE_ALL: 'Eliminar todo',
  ACTION_DELETE_FILTERED: 'Eliminar filtrados',
  ACTION_DISTRIBUTE: 'Distribuir',
  ACTION_DUPLICATE: 'Duplicar',
  ACTION_DOWNLOAD: 'Descargar',
  ACTION_DOWNLOAD_TABLE: 'Descargar tabla',
  ACTION_DOWNLOAD_TEMPLATE: 'Descargar plantilla',
  ACTION_EDIT: 'Editar',
  ACTION_EDIT_NAME: 'Editar nombre',
  ACTION_ENTER: 'Ingresar',
  ACTION_EXIT: 'Salir',
  ACTION_FINISH: 'Finalizar',
  ACTION_MORE: 'Más acciones',
  ACTION_NOTIFICATE: 'Notificar',
  ACTION_OPEN_DETAIL: 'Ver detalle',
  ACTION_PUBLISH: 'Publicar',
  ACTION_REMOVE: 'Quitar',
  ACTION_RETURN: 'Volver',
  ACTION_RETURN_ANYWAY: 'Volver de todos modos',
  ACTION_SAVE: 'Guardar',
  ACTION_SAVE_CHANGES: 'Guardar cambios',
  ACTION_SEARCH: 'Buscar',
  ACTION_SELECT: 'Seleccionar',
  ACTION_STAGE: 'Simular escenario',
  ACTION_START: 'Iniciar',
  ACTION_STORE: 'Archivar',
  ACTION_UPLOAD: 'Cargar',
  ACTION_UPLOAD_FILE: 'Cargar archivo',
  ACTION_UPLOAD_START: 'Iniciar carga',
  ACTION_UNDERSTAND: 'Entendido',
  ACTION_NEXT: 'Siguiente',
  ACTION_DELETE_SELECTION: 'Eliminar selección',
  ACTION_DELETE_PAGE: 'Eliminar página',
  ACTION_MODIFY: 'Modificar',
  ACTION_UNDO: 'Deshacer',
  ACTION_GO_TO_DETAIL: 'Ir al detalle',
  ACTION_UNAPPLY: 'Desaplicar',
  ACTION_VIEW: 'Visualizar',
  ACTION_UPDATE_DATA: 'Actualizar datos',
  ACTION_ANALYTICS_PATCH: 'Ver analíticas',
  ACTION_FIX: 'Fijar',
  ACTION_KEEP: 'Conservar',
  ACTION_SHOW_COMMENTS: 'Mostrar comentarios',
  ACTION_COMMENT: 'Comentar',
  ACTION_READ_MORE: 'Leer más',
  ACTION_HIDE: 'Ocultar',
  ACTION_SHOW: 'Mostrar',
  ACTION_REPLICATE: 'Replicar',
  ACTION_REPLICATE_VALUE: 'Replicar valor',
  ACTION_EXPORT: 'Exportar',
  ACTION_DOWNLOAD_PPT: 'Descargar ppt',
  ACTION_SORT_ASC: 'Ordenar de manera ascendente',
  ACTION_SORT_DESC: 'Ordenar de manera descendente',
  ACTION_GO: 'Ir',
  ACTION_SKIP: 'Omitir',
  ACTION_LOAD_EXCHANGE_RATE: 'Cargar tipo de cambio',
  ACTION_GO_TO_CONFIGURE: 'Ir a configuración',
  ACTION_CONFIRM: 'Confirmar',
  ACTION_ACTIVATE: 'Activar',
  ACTION_DEACTIVATE: 'Desactivar',
  ACTION_CONNECT: 'Conectar',
  ACTION_DISCONNECT: 'Desconectar',
  ACTION_UPLOAD_DATA: 'Cargar datos',
  ACTION_ASSOCIATE_DATA: 'Asociar datos',
  ACTION_PREVIOUS: 'Anterior',
  ACTION_BEGIN: 'Comenzar',
  ACTION_GROUP: 'Agrupar',
  ACTION_SEND: 'Enviar',
  ACTION_ADD_NEW: 'Añadir nuevo',
  ACTION_SORT: 'Ordenar',
  ACTION_CREATE_TRANSACTION: 'Crear transaciones',
  ACTION_PREDETERMINED_PIN_UP: 'Fijar como predeterminado',
  ACTION_ADD_FAVORITE: 'Añadir como favorito',
  ACTION_CHANGE_COLOR: 'Cambiar color',
  ACTION_SOLVE: 'Resolver',
  ACTION_REPLY: 'Responder',
  ACTION_NEW_THREAD: 'Nuevo hilo',

  //States
  STATE_TAG_APPROVED: 'Aprobado',
  STATE_TAG_CANCELED: 'Anulado',
  STATE_TAG_EJECUTION: 'En ejecución',
  STATE_TAG_ELABORATION: 'En elaboración',
  STATE_TAG_FINISHED: 'Finalizado',
  STATE_TAG_PUBLISHED: 'Publicado',
  STATE_TAG_STARTED: 'Iniciado',
  STATE_TAG_ACTIVE: 'Activo',
  STATE_TAG_INACTIVE: 'Inactivo',
  STATE_TAG_UNDEFINED: 'Sin definir',
  STATE_TAG_FINISHED_SUCCESS: 'Finalizado con éxito',
  STATE_TAG_CONFIGURED: 'Configurado',
  STATE_TAG_NOT_CONFIGURED: 'No configurado',
  STATE_TAG_LOADED: 'Cargado',
  STATE_TAG_PROCESSING: 'Procesando',
  STATE_TAG_PENDING: 'Pendiente',
  STATUS_DATE_APPROVE: 'Aprobada',
  STATUS_DATE_PUBLISH: 'Publicada',
  STATUS_TAG_CLOSED: 'Cerrado',
  LOADING_STATUS: 'Estado de carga',
  LOADING_STATUS_ERROR: 'Error',
  LOADING_STATUS_SUCCESS: 'Completa',

  //BUDGET_STATUS
  // TODO: Definir todos los estados, en acuerdo con back y UI
  in_elaboration: 'En elaboración',
  making: 'En elaboración',
  approved: 'Aprobado',
  published: 'Publicado',
  initiated: 'Iniciado',

  //Entities labels
  LABEL_ACTIVITY: 'Actividad',
  LABEL_ACTIVITIES: 'Actividades',
  LABEL_ACCOUNTS: 'Plan de cuentas',
  LABEL_ADJUSTMENTS: 'Ajustes',
  LABEL_ANALYSIS: 'Análisis',
  LABEL_BUDGET: 'Presupuesto',
  LABEL_BUDGETS: 'Presupuestos',
  LABEL_BUDGET_TYPE: 'Tipo de presupuesto',
  LABEL_CONFIG: 'Configuración',
  LABEL_CONTROL: 'Seguimiento',
  LABEL_DETAIL: 'Detalle',
  LABEL_DIMENSIONS: 'Dimensiones',
  LABEL_DISTRIBUTIONS: 'Distribuciones',
  LABEL_VALUES_DIMENSION: 'Valores de dimensión',
  LABEL_EXPENSES: 'Gastos',
  LABEL_FORECAST: 'Forecast',
  LABEL_PERIODS: 'Períodos',
  LABEL_PERIOD: 'Período',
  LABEL_PLANNING: 'Planificación',
  LABEL_PROFILE: 'Perfil',
  LABEL_REPORTS: 'Reportes',
  LABEL_REPORT: 'Reporte',
  LABEL_OTHERS: 'Otros',
  LABEL_SALES: 'Ventas',
  LABEL_USERS: 'Usuarios',
  LABEL_USER: 'Usuario',
  LABEL_SECURITY: 'Seguridad',
  LABEL_ROLES: 'Roles',
  LABEL_ROLE: 'Rol',
  LABEL_VARIABLE: 'Variable',
  LABEL_VARIABLES: 'Variables',
  LABEL_MODIFIERS: 'Modificadores',
  LABEL_PROJECTION_ELEMENTS: 'Elementos de proyección',
  LABEL_FORMULAS: 'Fórmulas',
  LABEL_FORMULA: 'Fórmula',
  LABEL_BUSINESS_RULES: 'Reglas de negocio',
  LABEL_BUSINESS_RULE: 'Regla de negocio',
  LABEL_FACTOR: 'Factor',
  LABEL_FACTORS: 'Factores',
  LABEL_GLOBAL_BUDGET: 'Presupuesto global',
  LABEL_GLOBAL_FORECAST: 'Forecast global',
  LABEL_NEW: 'Nuevo',
  LABEL_CONVENTIONAL_LOAD: 'Carga convencional',
  LABEL_FLAT_LOAD: 'Carga plana',
  LABEL_RESUME: 'Resumen',
  LABEL_FINANCIAL_GUIDELINES: 'Pautas financieras',
  LABEL_REAL: 'Real',
  LABEL_NAME_FILE: 'Nombre de archivo',
  LABEL_FILE_TYPE: 'Tipo de archivo',
  LABEL_LOAD_DETAILS: 'Detalle de cargas',
  LABEL_LOAD_DETAIL: 'Detalle de carga',
  LABEL_LOAD_DATA: 'De datos',
  LABEL_LOAD_ASSOCIATION_DATA: 'Asociación cuenta-concepto',
  LABEL_EXCHANGE_RATE: 'Tipo de cambio',
  LABEL_CURRENCY: 'Moneda',
  LABEL_MULTICURRENCY: 'Multimoneda',
  LABEL_RATIO: 'Ratio',
  LABEL_FINANCIAL: 'Financieros',
  LABEL_ECONOMIC: 'Económicos',
  LABEL_STATUS_CHANGE: 'Cambio de estado',
  LABEL_DESTINATION_PROJECTION: 'Proyección destino',
  LABEL_MY_DATA: 'Mis datos',
  LABEL_COSTS: 'Costos',
  LABEL_HUMAN_RESOURCES: 'Recursos humanos',
  LABEL_CLOSING: 'Cierre',
  LABEL_VISUALIZATION_TABLE: 'Visualización tabla',
  LABEL_TUTORIALS: 'Tutoriales',
  LABEL_HELP: 'Ayuda',
  LABEL_USER_MANUAL: 'Manual de usuario',
  LABEL_ROLES_AND_USERS: 'Roles y usuarios',
  LABEL_EXAMPLE_TEMPLATE: 'Planillas ejemplo',
  LABEL_INTEGRATION: 'Integraciones',
  LABEL_STANDAR_TABLE: 'Tabla estandar',
  LABEL_NESTED_TABLE: 'Tabla anidada',
  LABEL_NAME_CHATBOT: 'Plika AlphaBot',
  LABEL_FILE: 'Archivo',
  LABEL_PROCESS_START: 'Inicio de proceso',
  LABEL_PROCESS_END: 'Fin de proceso',
  LABEL_PROCESS_UPLOAD: 'Procesos de carga',
  LABEL_PROJECTION: 'Proyección',
  LABEL_DASHBOARD: 'Dashboard',
  LABEL_SOCIETIES: 'Empresas',
  LABEL_QUESTION: 'Configuración de preguntas',
  LABEL_OR: 'o',
  LABEL_TOOLS: 'Herramientas',
  LABEL_DELIMITERS: 'Delimitadores',
  LABEL_DELIMITER_THOUSANDS: 'Delimitadores de miles',
  LABEL_DELIMITER_DECIMAL: 'Delimitadores decimales',
  LABEL_PLANNING_INSTANCE: 'Planificacion instancia',
  LABEL_FORECAST_INSTANCE: 'Forecast instancia',
  LABEL_REPORT_INSTANCE: 'Reporte instancia',
  LABERL_PREVIUS_REAL: 'Real anterior',
  LABEL_COMMENTS: 'Comentarios',
  LABEL_ACTIVE: 'Activos',
  LABEL_SOLVED: 'Resueltos',
  LABEL_TYPE_VALUES_REFERENCE: 'Valores en {{type}}',
  LABEL_EXAMPLE: 'Ejemplo',

  LABEL_ABBREVIATIONS: 'Abreviaturas',
  LABEL_ABBREVIATION_THOUSAND: 'Abreviatura de miles',
  LABEL_ABBREVIATION_MILLION: 'Abreviatura de millón',
  LABEL_ABBREVIATION_BILLION: 'Abreviatura de mil millones',
  LABEL_ABBREVIATION_TRILLION: 'Abreviatura de trillón',

  //Fields
  REQUIRED_FIELD: 'Este campo es obligatorio',
  REQUIRED_FIELD_CHARACTERS: 'No se pueden superar los {{cant}} caracteres',
  FIELD_ALL: 'Todas',
  FIELD_ACCOUNT: 'Cuenta',
  FIELD_ACCOUNTS: 'Cuentas',
  FIELD_ACTION: 'Acción',
  FIELD_ACTIONS: 'Acciones',
  FIELD_AMOUNT: 'Importe',
  FIELD_BEHAVIOR: 'Comportamiento',
  FIELD_CODE: 'Código',
  FIELD_COMPARISONS: 'Comparaciones',
  FIELD_SEGMENTED: 'Segmentado',
  FIELD_CONCEPT: 'Concepto',
  FIELD_PRICE: 'Precio',
  FIELD_COUNT: 'Cantidad',
  FIELD_DATE: 'Fecha',
  FIELD_CREATION_DATE: 'Fecha de creación',
  FIELD_CREATOR_INFO: 'Creado por {{username}}',
  FIELD_DEADLINE: 'Fecha límite de carga',
  FIELD_DESCRIPTION: 'Descripción',
  FIELD_DIMENSION: 'Dimensión',
  FIELD_DIMENSION_DIMENSIONS: 'Dimensión/es',
  FIELD_DIMENSION_VALUES: 'Valor/es de dimensión',
  FIELD_EMAIL: 'Email',
  FIELD_END_DATE: 'Mes de fin',
  FIELD_IMPACT: 'Impacto',
  FIELD_NAME: 'Nombre',
  FIELD_TIME: 'Hora',
  FIELD_OBSERVATIONS: 'Observaciones',
  FIELD_ORDER: 'Orden',
  FIELD_ORIGINAL: 'Original',
  FIELD_PERCENTAGE: 'Porcentaje',
  FIELD_ABSOLUTE: 'Absoluto',
  FIELD_START_DATE: 'Mes de inicio',
  FIELD_STATUS: 'Estado',
  FIELD_SURNAME: 'Apellido',
  FIELD_TOTAL: 'Total',
  FIELD_TYPE: 'Tipo',
  FIELD_DATA_TYPE: 'Tipo de dato',
  FIELD_VALUE: 'Valor',
  FIELD_VALUE_TYPE: 'Tipo de valor',
  FIELD_CANCELLATION: 'Anulación',
  FIELD_YEAR_MONTH: 'Año/mes',
  FIELD_MOVEMENTS: 'Movimientos',
  FIELD_ASSOCIATED_CONCEPT: 'Concepto Asociado',
  FIELD_ASSOCIATED_CONCEPTS: 'Conceptos asociados',
  FIELD_ASSOCIATED_CONCEPT_CONCEPTS: 'Concepto/s asociado/s',
  FIELD_EVOLUTION: 'Evolución mensual',
  FIELD_ASSOCIATED_DIMENSIONS: 'Dimensiones asociadas',
  FIELD_ASSOCIATED_DIMENSION: 'Dimensión asociada',
  FIELD_COMMENT: 'Comentario',
  FIELD_ATENTION: '¡Atención!',
  FIELD_IMPORTANT: '¡Importante!',
  FIELD_LEVELS: 'Niveles',
  FIELD_LEVEL: 'Nivel',
  FIELD_ACCOUNTS_IMPACTED: 'Cuenta(s) en la(s) que impacta',
  FIELD_TOTAL_AMOUNT: 'Total importe',
  FIELD_TOTAL_LINES: 'Total lineas',
  FIELD_PREDETERMINED_BUDGET: 'Presupuesto predeterminado',
  FIELD_APPLIED: 'Aplicada',
  FIELD_UNAPPLIED: 'Desaplicada',
  FIELD_LINE_REPORT: 'Línea de reporte',
  FIELD_LOAD_TYPE: 'Tipo de carga',
  FIELD_RED: 'Rojo',
  FIELD_PRINCIPAL: 'Principal',
  FIELD_SECONDARY: 'Secundario',
  FIELD_NUMBER: 'Número',
  FIELD_CURRENCY_TYPE: 'Tipo de moneda',
  FILED_ORIGIN_CURRENCY: 'Moneda origen',
  FIELD_FUNCTIONAL_CURRENCY: 'Moneda funcional',
  FIELD_ORIGIN_CURRENCIES: 'Monedas de origen',
  FIELD_FULL_VALUE: 'Valor completo',
  FIELD_VARIATION: 'Variación',
  FIELD_LAST_UPDATED: 'Última actualización',
  FIELD_DIMENSIONS_AMOUNT: 'Cantidad de dimensiones',
  FIELD_TRANSACTION: 'Transacción',
  FIELD_CONNECTOR: 'Conector',
  FIELD_SOURCE: 'Fuente',
  FIELD_TABLE: 'Tabla',
  FIELD_CHART: 'Gráfico',
  FIELD_TITLE: 'Título',
  FIELD_CHART_TYPE: 'Tipo de gráfico',
  FIELD_ELMENT_TYPE: 'Tipo de elemento',
  FIELD_SIZE: 'Tamaño',
  FIELD_REPORT_LINE: 'Linea de reporte',
  FIELD_QUESTION: 'Pregunta',
  FIELD_DOCUMENTATION: 'Documentación',
  FIELD_AUTHENTICATION: 'Autenticación',
  FIELD_FILE_S3_UPLOAD: 'Carga Archivo s3',
  FIELD_CUSTOM_BOTTOM: 'Botones personalizados',
  FIELD_FORMAT: 'Formatos',
  FIELD_QR_BUTTOM: 'Código QR',
  FIELD_PREDETERMINED: 'Predeterminado',
  FIELD_FAVORITE: 'Favorito',
  FIELD_BEST_SELLERS: 'Mayor %',
  FIELD_LESS_SOLD: 'Menor %',
  FIELD_REPORT_TYPE: 'Tipo de reporte',
  FIELD_REPORT_INITIAL_AMOUNT: 'Monto inicial',
  FIELD_TAB_TRANSACTIONAL: 'Transacional',
  FIELD_TAB_PERCENTAGE: 'Porcentual',
  FIELD_DECIMALS: 'Decimales',
  FIELD_VERSIONED: 'Versionado',
  FIELD_SIGN: 'Signo',

  //Placeholders
  ACCOUNT_PLACEHOLDER: 'Seleccioná una cuenta',
  DIMENSION_PLACEHOLDER: 'Seleccioná una dimensión',
  MONTH_PLACEHOLDER: 'Seleccioná un mes',
  UPLOAD_FILE_PLACEHOLDER: 'Seleccioná el archivo que deseas subir',
  VALUES_PLACEHOLDER: 'Seleccioná los valores',
  VALUE_PLACEHOLDER: 'Seleccioná un valor',
  ROW_SELECT_PLACEHOLDER: 'Selecccioná una línea',
  WRITE_COMMENT_PLACEHOLDER: 'Usa @ para mencionar',
  ENTER_AMOUNT_PLACEHOLDER: 'Ingresar importe',
  ENTER_CODE_PLACEHOLDER: 'Ingresar código',
  ENTER_NAME_PLACEHOLDER: 'Ingresar nombre',
  ENTER_QUESTION_PLACEHOLDER: 'Ingresar pregunta',
  ENTER_QUANTITY_PLACEHOLDER: 'Ingresar cantidad',
  ENTER_PRICE_PLACEHOLDER: 'Ingresar precio',
  SELECT_CURRENCY_PLACEHOLDER: 'Seleccionar moneda',

  //Feedbacks
  FEEDBACK_LOADING: 'Cargando...',
  FEEDBACK_LOADING_CHANGES: 'Guardando cambios...',
  FEEDBACK_DEFAULT_ERROR: 'Tuvimos un problema',
  FEEDBACK_EDIT_REPORT_NAME_ERROR: 'El campo no puede estar vacío',
  FEEDBACK_SAVE_CHANGES_SUCCES: 'Cambios guardados',
  FEEDBACK_SAVE_CHANGES_FAIL_MESSAGE: 'Error al guardar cambios',
  FEEDBACK_CHANGES_SAVED_SUCCESS: 'Los cambios fueron guardados exitosamente',
  FEEDBACK_SAVE_CHANGES_FAIL: 'Ocurrió un error al intentar guardar los cambios',
  FEEDBACK_CREATION_FAIL: 'Ocurrió un error en la creación. Por favor, volvé a intentarlo.',
  FEEDBACK_DELETE_FAIL: 'Ocurrió un error en la eliminación. Por favor, volvé a intentarlo.',
  FEEDBACK_UPLOAD_FILE_SUCCESS: 'Se ha subido correctamente el archivo de {{configModule}}',
  FEEDBACK_UPLOAD_PROJECITION_FILE_SUCCESS:
    'Ya se cargaron todos los datos del archivo en el {{projectionType}}. {{descriptionType}}',
  FEEDBACK_UPLOAD_FILE_WITH_ERRORS: 'Se cargó el archivo pero con algunos errores.',
  FEEDBACK_UPLOAD_FILE_ERROR: '¡Ups! Ocurió un error al intentar cargar el archivo {{fileName}}.',
  FEEDBACK_UPLOAD_FILE_HEADBOARD_ERROR: 'Error de cabecera inválida',

  FEEDBACK_WELLDONE: '¡Buen trabajo!',
  FEEDBACK_VERY_GOOD: '¡Muy bien!',
  FEEDBACK_ACTION_DEFAULT_ERROR: 'Volver a intentar en un momento para {{action}}.',
  FEEDBACK_DEFAULT_NOT_FOUND: 'No encontramos resultados',
  FEEDBACK_NOT_DATA_REQUEST: '¡Lo sentimos! No pudimos obtener los datos solicitados.',
  FEEDBACK_DELETE_SELECTION_SUCCESS: 'La selección se eliminó correctamente',
  FEEDBACK_DELETE_DATA_SUCCESS: 'Los datos se eliminaron correctamente',
  FEEDBACK_DELETE_SELECTION_ERROR:
    'No pudimos eliminar la selección. Por favor, volvé a intentarlo.',
  FEEDBACK_DELETE_DATA_ERROR: 'No pudimos eliminar los datos. Por favor, volvé a intentarlo.',
  FEEDBACK_CREATE_SUCCESS: 'La creación fue exitosa',
  FEEDBACK_INPUT_PERCENTAGE: 'Asignar porcentaje a el/los valor/es filtrado/s',
  FEEDBACK_EXPORT_CHARTS_AS_IMAGE_ERROR:
    'Ocurrió un error al exportar. Por favor volvé a intentarlo',
  FEEDBACK_CONFIRM_ERROR_DESCRIPTION: 'Ocurrió un error durante la confirmación',
  MESSAGE_PROCESS_STATUS_SUCCESS: 'Carga finalizada con éxito',
  MESSAGE_PROCESS_STATUS_PENDING: 'Proceso pendiente',
  MESSAGE_PROCESS_STATUS_PROCESSING: 'Procesando la informacion cargada',
  FEEDBACK_LOAD_DATA_SUCCESS: 'Se cargaron los datos con éxito',
  FEEDBACK_LOAD_DATA_ERROR: 'Ocurrió un error al cargar los datos.',
  FEEDBACK_CONGRATULATIONS: '¡Felicidades!',
  FEEDBACK_DOWNLOAD_FILE_ERROR: 'Ocurrió un error al descargar el archivo.',
  FEEDBACK_DOWNLOAD_FILE_SUCCESS: 'Descarga exitosa',
  FEEDBACK_DELETE_SUCCESS: 'Se eliminaron los datos exitosamente',
  FEEDBACK_DUPLICATE_SUCCESS: 'Se duplicaron los datos exitosamente',
  FEEDBACK_DUPLICATE_ERROR: 'Ocurrió un error al duplicar los datos',
  FEEDBACK_OTHER_LOGIN_ERROR: 'Ocurrió un error al intentar iniciar sesión',
  FEEDBACK_SELECT_ACCOUNT_ERROR: 'Ocurrió un error al seleccionar la cuenta para iniciar sesión',
  FEEDBACK_SETTING_SUCCESS: 'Configuración exitosa',
  FEEDBACK_SELECT_DATA_ERROR:
    'Debes elegir como máximo 5 rows y 5 cols para poder visualizar la informacion',
  FEEDBACK_FUNCTION_ACTION_SUCCESS: 'Ejecución correcta',
  FEEDBACK_UPLOAD_AMOUNT_P_Q_ERROR:
    'Has cargado datos en las tres columnas: importe, precio y cantidad. Para que los datos se carguen correctamente solo puedes cargar en la columna importe, o solo en la de precio con cantidad o precio y cantidad individualmente.',
  FEEDBACK_SEND_MAIL_SUCCESS: 'Envio de mail exitoso',
  FEEDBACK_SEND_MAIL_ERROR: 'Ocurrió un error al enviar el mail',
  FEEDBACK_GENERAL_DEFAULT: 'Ocurrió un error al generar la acción',
  FEEDBACK_ERROR_500: 'Por favor, comuníquese con el administrador del producto',
  FEEDBACK_UPLOAD_DATA_FILE_SUCCESS:
    'Para mas información sobre el progreso de la carga de los datos dirígete a ',
  FEEDBACK_EVERYTHING_READY: '¡Todo listo!',
  FEEDBACK_SPREADSHEET_TEXT:
    'En el siguiente enlace puedes encontrar el acceso a la hoja de cálculo',
  FEEDBACK_DEFAULT_TEXT: 'Para realizar la siguiente acción, puedes dar',
  FEEDBACK_LOAD_TEXT: 'Acción en progreso...',

  //Info texts
  TABLE_SETTING_MODAL_TITLE: 'Configure el aspecto  del contenido y forma de su tabla',
  DISPLAY_MODAL_SETTINGS_TITLE: 'Preferencias de visualización {{chartName}}',
  COLUMNS_SETTING_MODAL_PLACEHOLDER: 'Selecciona las columnas que deseas ver',
  ALERT_NOT_ALLOWED: 'No tienes permisos para realizar esta acción.',
  ALERT_NULL_DATA: 'Aún te quedan datos por cargar',
  ALERT_INCOMPLETE_VALUES: 'Valores incompletos',
  EMPTY_STATE_DEFAULT_TITLE: '¡Manos a la obra!',
  EMPTY_STATE_DRAG_FILE_TEXT: 'Hacé click o arrastrá el archivo a esta área para cargar.',
  EMPTY_STATE_NO_DATA_TITLE: 'Aún no tenemos información para mostrar',
  EMPTY_STATE_UPLOAD_DEFAULT_DESCRIPTION: 'Comenzá la carga de tus archivos',
  UPLOAD_FILE_TITLE: 'Carga de archivo en Plika',
  INFO_UPLOAD_FILE_MODAL_DESCRIPTION:
    'Para poder realizar la carga, recordá armar tu archivo con el template predeterminado.',
  INFO_UPLOAD_FILE_DOWNLOAD_ACTION: 'Descargalo aquí.',
  INFO_UPLOAD_FILE_BODY_1:
    'Recordá que todos los archivos que cargues deben estar creados con la plantilla que hemos predeterminado para vos y tu equipo.',
  INFO_UPLOAD_FILE_BODY_2: 'Si aún no la tenés, podés descargarla desde acá.',
  INFO_UPLOAD_TOOLTIP: 'Podés hacer cargas individuales o masivas',
  EXIT_MODAL_DESCRIPTION:
    'Al salir, perdés toda la información que cargaste hasta el momento. ¿Salimos de todos modos?',
  UPLOAD_ERRORS_FILE_DOWNLOAD_TEXT_1: 'Descargá este',
  UPLOAD_ERRORS_FILE_DOWNLOAD_TEXT_2: 'archivo',
  UPLOAD_ERRORS_FILE_DOWNLOAD_TEXT_3: 'para obtener más información de los errores ocurridos.',
  POPCONFIRM_DELETE_SELECTION_TITLE: '¿Estás seguro que deseas eliminar esta selección?',
  POPCONFIRM_DELETE_DEFAULT_TITLE: '¿Estás seguro que deseas eliminar {{name}}?',
  POPCONFIRM_DELETE_FILTER_DATA: '¿Estas seguro que deseas eliminar los datos filtrados?',
  POPCONFIRM_DELETE_ALL_PAGES:
    'Estas a punto de eliminar todo el contenido, ¿estás seguro que deseas eliminar todas las páginas?',
  TOOLTIP_NOT_APPROVE_TITLE: 'Primero debes publicar los datos para habilitar esta acción',
  POPCONFIRM_EDIT_CANCEL: '¿Estás seguro que deseas cancelar la edición?',
  POPCONFIRM_SAVE_CHANGES: '¿Estás seguro que quieres guardar los cambios realizados?',
  POPOVER_NOT_GLOBAL_BUDGET_FIXED:
    'No hay {{type}} fijado. Para visualizar sus datos debes fijarlo como predetermiando.',
  POPOVER_DEFAULT_GLOBAL_BUDGET_DESCRIPTION:
    'Estos son tus presupuestos predeterminados. Para modificarlos debes ir a la solapa de presupuesto global de planificación y forecast respectivamente.',
  POPOVER_NOT_DEFAULT_GLOBAL_BUDGET_DESCRIPTION:
    'No hay presupuestos predeterminados. Para visualizarlos debes ir a la solapa presupuesto general de planificación y forecast respectivamente.',
  TOOLTIP_CHECK_SOLVED_TITLE: 'Marcar como resuelto',
  INPUT_ORDER_KPIS_TITLE: 'Orden KPI',
  MODAL_DELETE_CONFIRM_TEXT: '¿Deseas eliminar de todas formas?',
  UPLOAD_SCREEN_DESCRIPTION: 'Comenzá la carga de tus datos',
  REFERENCE_DESCRIPTION: 'Valores en {{currencyName}}',
  SELECT_OPTION_TITLE: '¿Cómo desas continuar?',
  CONFIRM_ACTION_TITLE: 'Confirmar acción',
  NEED_HELP: '¿Necesitas ayuda con Plika?',
  WRITE_SOMETHING_PLACEHOLDER: 'Escribe algo...',
  POPCONFIRM_REMOVE_DASHBOARD_ELEMENT: '¿Estás seguro que deseas quitar este elemento?',
  TYPOGRAPHY_ENTER_VIEW_NAME: 'Escribe un nombre para la vista',
  DATE_FORMAT_PLACEHOLDER: 'Formato fecha',
  INFORMATIVE_ALERT_UPLOAD_DATA_CURRENCY:
    'Los datos que cargues se verán reflejados en la moneda que tengas por defecto.',
  EMPTY_NOT_SOLVED_COMMENTS_DESCRIPTION: 'Aún no hay comentarios resueltos',
  EMPTY_NOT_COMMENTS_DESCRIPTION: 'Aún no hay comentarios',
  POPCONFIRM_REMOVE_COMMENT: 'Estas seguro que deseas eliminar este comentario?',
  DELETE_ALL_INFORMATIVE_TOOLTIP_TITLE:
    'Elimina toda la información de esta tarjeta. {{extraText}}',
  DELETE_SELECTION_INFORMATIVE_TOOLTIP_TITLE:
    'Elimina la información de la actual selección de la tabla. {{extraText}}',
  DIMENSION_TABLE_INFORMATIVE_TOOLTIP_TITLE:
    'Para elegir la preferencia de visualizacion en la tabla (por código o descripción) dirigite a Configuración > Dimensiones > Preferencias.',
  SELECT_UNIT_MEASURE_INFORMATIVE_TOOLTIP_TITLE:
    'Selecciones la unidad de medida en la que desea ver la información de la tabla.',
  TAB_RULES_BUTTON_TOOLS_INFORMATIVE_TOOLTIP_TITLE:
    'Dentro de esta sección podras visualizar y aplicar las reglas de negocio creadas con anterioridad en el módulo de Configuración con el objetivo de cargar valores individualizados en las mismas.',
  FAVOURITE_ICON_INFORMATIVE_TOOLTIP_TITLE:
    'Permite añadir como favorito el layout de dimensiones establecido en la tabla.',
  ADD_NEW_TRANSACTION_INFORMATIVE_TOOLTIP_TITLE:
    'Permite añadir una nueva transacción en Plika por interfaz contemplando las alternativas de carga de datos existe en la herramienta.',
  SELECT_TABLE_TYPE_INFORMATIVE_TOOLTIP_TITLE:
    'Dentro de este selector podrás definir en qué formatos predeterminados visualizar la información de la tabla.',
  TAB_CURRENCY_BUTTON_TOOLS_INFORMATIVE_TOOLTIP_TITLE:
    'Dentro de esta sección podrás establecer las relaciones entre cada una de las monedas configuradas. Este input permitirá ver la información correctamente convertida.',
  EXTRA_TEXT_DELETE_INFORMATIVE_TOOLTIP_TITLE:
    'Solo aplicaría ese borrado para los meses proyectados.',

  //Indicators
  CORESPONDS: 'Correspode a {{cuenta}}',
  DELTA: 'Delta',
  REAL: 'Real',
  SALES_TOTAL: 'Total ventas',
  EXPENSES_TOTAL: 'Total gastos',
  LAST_FORECAST: 'Último forecast',
  REAL_LAST_PERIOD: 'Real período anterior',
  REAL_LAST_YEAR: 'Real año anterior',
  VARIATION_MONEY: 'Var {{prefix}}',
  VARIATION_PERCENTAGE: 'Var %',
  PREV_BUDGET: 'Presupuesto período anterior',
  LAST_PERIOD: 'Período anterior',

  budget: 'Presupuesto',
  real: 'Real',
  prev_real: 'Real periodo anterior',
  last_forecast: 'Último forecast',
  // Ventas: 'Ventas',
  // Gastos: 'Gastos',
  init: 'Inicial',
  secuencial: 'Secuencial',
  over_amount: 'Sobre Importe',
  OTROS: 'Otros',

  integer: 'Enteros',
  decimals: 'Decimales',
  Thousands_millions: 'Miles/millones',
  auto: 'Automático',
  predefined: 'Predefinido',
  sales: 'Ventas',
  expenses: 'Gastos',
  pnl: 'P&L',

  DATE_AT_TIME: 'a las',

  DETAIL_TITLE: 'Detalle {{name}}',
  DUPLICATE_NAME: '{{name}} - duplicado ',
  COPY_NAME: '{{name}} - copia ',

  SHOW_FILTERS_MENU: 'Mostrar filtros',
  HIDE_FILTERS_MENU: 'Ocultar filtros',

  GLOBAL_BUDGET_FILE_NAME: 'Plika - Presupuesto global',

  DISPLAY_TABLE_SETTING: 'Visualización números',

  // CHARTS
  CHARTS_MODAL_SETTINGS_SUBTITLE: 'Configure el aspecto para la visualización de sus gráficos',
  FIELD_LABEL: 'Etiquetas',
  FIELD_LEGENDS: 'Leyendas',
  LABEL_LAYOUT: 'Layout',
  LABEL_DATA: 'Datos',
  LABEL_PADDING: 'Padding',
  LABEL_POSITION_MODE: 'Modo de disposición',
  LABEL_DIMENSION_FILTER: 'Seleccione los valores que desea reflejar',
  LABEL_GROUPED: 'Agrupados',
  LABEL_STACKED: 'Apilados',
  LABEL_INNER: 'Interior',
  LABEL_OUTER: 'Exterior',
  LABEL_NAME_VALUE: 'Nombre:valor',
  LABEL_FORMAT: 'Formato',
  LABEL_VALUE_TYPE: 'Valor',
  LABEL_VALUE_POSITION: 'Ubicación',
  LABEL_FONT_SIZE: 'Tamaño de letra',
  CHARTS_VALUES_QUANTITY: 'Cantidad de valores visibles',
  CHARTS_TOOLTIP_INFO_LEGENDS:
    'Podrás visualizar una leyenda a la vez para cada gráfico de torta. Si quieres ver el de uno en particular, haz click en el y lo verás a la izquierda del mismo', //TODO: Pedir tooltip a UI
  CHART_TYPE_PIE: 'Torta',
  CHART_TYPE_BAR: 'Barras',
  CHART_TYPE_LINE: 'Lineas',
  CHART_TYPE_SANKEY: 'Sankey',
  CHART_SMALL_SIZE: 'Chico',
  CHART_MEDIUM_SIZE: 'Mediano',
  CHART_LARGE_SIZE: 'Grande',
  CHART_EXPORT_AS_IMAGE_FILE_NAME: 'Gráfico {{chartTitle}} {{date}}.{{format}}',
  CHART_DISPLAY_MODE_SETTINGS: 'Modo de visualizacion',
  CHART_SORTING_MODE_SETTINGS: 'Modo de ordenamiento',
  CHART_PROVISION_SETTINGS: 'Disposición',
  CHART_VIEW_AREA_SETTINGS: 'Visualizar área',
  CHART_VIEW_POINTS_SETTINGS: 'Visualizar puntos',
  CHART_UPWARD_SETTINGS: 'Ascendente',
  CHART_FALLING_SETTINGS: 'Descendente',
  LABEL_CHAT_BOT_START_CHAT: 'Seleccione una pregunta para iniciar el chat',

  LABEL_OPTION_TITLE_REPLACE: 'Reemplazar los datos actuales:',
  LABEL_OPTION_TEXT_REPLACE:
    'Se eliminan los datos correspondientes a los meses que estamos actualizando, permitiendo que los nuevos datos se conviertan en la fuente principal.',
  LABEL_OPTION_TITLE_ADD: 'Adicionar a los datos actuales:',
  LABEL_OPTION_TEXT_ADD:
    'Se identifican las combinaciones existentes que coinciden con las nuevas transacciones, y se les suman los valores de las nuevas operaciones. También, se añaden transacciones a combinaciones con valor 0.',
  LABEL_OPTION_TITLE_OVERWRITE: 'Sobreescribir los datos actuales:',
  LABEL_OPTION_TEXT_OVERWRITE:
    'Las transacciones con combinaciones de valores de dimensión idénticas se actualizan con los nuevos valores, mientras que las que no coinciden se crean.',

  TRANSACTION_DELETE_ACTION: 'Eliminar transacción',
  TRANSACTION_ADD_NEW_ACTION: 'Añadir otra transacción',
}

export default general
