import { useFeatureIsOn } from '@growthbook/growthbook-react'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { generateMessageError, isUserAllowed } from 'modules/core/utils'
import { CONCEPTS, GENERAL, PROJECTION, ROLES } from 'modules/core/constants'
import {
  handleDeleteSelection,
  renderButtonSendName,
  renderFunctionSend,
} from 'modules/core/components/ProjectionComponents/utils'
import { Col, Row, notification } from 'antd'
import { ButtonUploadData, TabsTables } from './components'
import {
  CreateTransactionModal,
  PhaseKpis,
  ProjectionUploadModal,
  SpreadsheetsModal,
  ToolsDrawer,
  UserActionsPhases,
} from 'modules/core/components'
import { useTranslation } from 'react-i18next'
import { handleDeleteAll } from './utils'
import planning from 'modules/planning'
import forecast from 'modules/forecast'
import control from 'modules/control'
import configuration from 'modules/configuration'
import moment from 'moment'
import login from 'modules/login'
import _ from 'lodash'

const {
  FORECAST__SALES__BASE__ABM,
  FORECAST__SALES__TOP_DOWN__ABM,
  FORECAST__SALES__BOTTOM_UP__ABM,
  FORECAST__SALES__CLOSING__ABM,
  FORECAST__EXPENSES__BASE__ABM,
  FORECAST__EXPENSES__TOP_DOWN__ABM,
  FORECAST__EXPENSES__BOTTOM_UP__ABM,
  FORECAST__EXPENSES__CLOSING__ABM,
  FORECAST__COSTS__BASE__ABM,
  FORECAST__COSTS__TOP_DOWN__ABM,
  FORECAST__COSTS__BOTTOM_UP__ABM,
  FORECAST__COSTS__CLOSING__ABM,
  FORECAST__HUMAN_RESOURCES__BASE__ABM,
  FORECAST__HUMAN_RESOURCES__TOP_DOWN__ABM,
  FORECAST__HUMAN_RESOURCES__BOTTOM_UP__ABM,
  FORECAST__HUMAN_RESOURCES__CLOSING__ABM,
  FORECAST__OTHER_5__BASE__ABM,
  FORECAST__OTHER_5__TOP_DOWN__ABM,
  FORECAST__OTHER_5__BOTTOM_UP__ABM,
  FORECAST__OTHER_5__CLOSING__ABM,
  FORECAST__OTHER_6__BASE__ABM,
  FORECAST__OTHER_6__TOP_DOWN__ABM,
  FORECAST__OTHER_6__BOTTOM_UP__ABM,
  FORECAST__OTHER_6__CLOSING__ABM,
} = ROLES

const FORECAST__CONCEPT = {
  AMB: {
    [CONCEPTS.IDS.SALES_ID]: {
      [PROJECTION.BASE]: FORECAST__SALES__BASE__ABM,
      [PROJECTION.TOPDOWN]: FORECAST__SALES__TOP_DOWN__ABM,
      [PROJECTION.BOTTOMUP]: FORECAST__SALES__BOTTOM_UP__ABM,
      [PROJECTION.CLOSING]: FORECAST__SALES__CLOSING__ABM,
    },
    [CONCEPTS.IDS.EXPENSES_ID]: {
      [PROJECTION.BASE]: FORECAST__EXPENSES__BASE__ABM,
      [PROJECTION.TOPDOWN]: FORECAST__EXPENSES__TOP_DOWN__ABM,
      [PROJECTION.BOTTOMUP]: FORECAST__EXPENSES__BOTTOM_UP__ABM,
      [PROJECTION.CLOSING]: FORECAST__EXPENSES__CLOSING__ABM,
    },
    [CONCEPTS.IDS.COSTS_ID]: {
      [PROJECTION.BASE]: FORECAST__COSTS__BASE__ABM,
      [PROJECTION.TOPDOWN]: FORECAST__COSTS__TOP_DOWN__ABM,
      [PROJECTION.BOTTOMUP]: FORECAST__COSTS__BOTTOM_UP__ABM,
      [PROJECTION.CLOSING]: FORECAST__COSTS__CLOSING__ABM,
    },
    [CONCEPTS.IDS.HUMAN_RESOURCES_ID]: {
      [PROJECTION.BASE]: FORECAST__HUMAN_RESOURCES__BASE__ABM,
      [PROJECTION.TOPDOWN]: FORECAST__HUMAN_RESOURCES__TOP_DOWN__ABM,
      [PROJECTION.BOTTOMUP]: FORECAST__HUMAN_RESOURCES__BOTTOM_UP__ABM,
      [PROJECTION.CLOSING]: FORECAST__HUMAN_RESOURCES__CLOSING__ABM,
    },
    [CONCEPTS.IDS.OTHER_5_ID]: {
      [PROJECTION.BASE]: FORECAST__OTHER_5__BASE__ABM,
      [PROJECTION.TOPDOWN]: FORECAST__OTHER_5__TOP_DOWN__ABM,
      [PROJECTION.BOTTOMUP]: FORECAST__OTHER_5__BOTTOM_UP__ABM,
      [PROJECTION.CLOSING]: FORECAST__OTHER_5__CLOSING__ABM,
    },
    [CONCEPTS.IDS.OTHER_6_ID]: {
      [PROJECTION.BASE]: FORECAST__OTHER_6__BASE__ABM,
      [PROJECTION.TOPDOWN]: FORECAST__OTHER_6__TOP_DOWN__ABM,
      [PROJECTION.BOTTOMUP]: FORECAST__OTHER_6__BOTTOM_UP__ABM,
      [PROJECTION.CLOSING]: FORECAST__OTHER_6__CLOSING__ABM,
    },
  },
}

const BaseForecast = ({
  forecastInstanceDetail,
  dataPhase,
  onRefetch,
  status_budget,
  tablePagination,
  fetchForecastKpis,
  forecastKPIs,
  setNextPhase,
  closingForecast,
  periodList,
  fetchDataNestedTableForecastByPhase,
  downloadControlTemplateXLS,
  uploadFileByPhaseForecast,
  applyRuleinstanceForecast,
  unApplyRuleInstanceForecast,
  massiveCreateForecastTransaction,
  fetchAllDimensions,
  fetchDateBlockingByForecast,
  fetchDataRdnPercentageByForecast,
  fetchRulesList,
  deleteAllTransaction,
  loggedUser,
  fetchCommentsList,
  commentsList,
}) => {
  const extraConcept = useFeatureIsOn('feature-extra-concept')
  const [showPopConfirmSend, setShowPopConfirmSend] = useState(false)
  const [isDataLoading, setIsDataLoading] = useState(false)
  const [showUploadModal, setShowUploadModal] = useState(false)
  const [isKpisLoading, setIsKpisLoading] = useState(false)
  const [isTableNestedLoading, setIsTableNestedLoading] = useState(false)
  const [showSpreadsheetsModal, setShowSpreadsheetsModal] = useState(false)
  const [showDrawerRules, setShowDrawerRules] = useState(false)
  const [showDeleteAll, setShowDeleteAll] = useState(false)
  const [isDeletingAll, setIsDeletingAll] = useState(false)
  const [typeOfLoad, setTypeOfLoad] = useState(PROJECTION.TYPE_UPLOAD_OPTION_DEFAULT)
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [dataComments, setDataComments] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  let { projectionId, forecastId, periodId } = useParams()
  let history = useHistory()
  const { t } = useTranslation()
  const [dateBlocking, setDateBlocking] = useState([])

  const [tabKey, setTabKey] = useState(PROJECTION.PLANNING_KEYS.TRANSACTIONAL_KEY)
  const [businessRuleId, setBusinessRuleId] = useState(null)

  const key = window.location.pathname.replaceAll('/', '-')

  useEffect(() => {
    setIsLoading(true)
    fetchCommentsList({ module: `${key}-${dataPhase.key}` }).then(() => setIsLoading(false))
  }, [fetchCommentsList, key, dataPhase.key])

  useEffect(() => {
    setDataComments(commentsList)
  }, [commentsList])

  useEffect(() => {
    fetchAllDimensions()
  }, [fetchAllDimensions, periodId])

  useEffect(() => {
    if (!_.isEmpty(forecastInstanceDetail)) {
      setIsKpisLoading(true)
      fetchForecastKpis({ step: dataPhase.key, forecast_kpis_id: projectionId }).then(() =>
        setIsKpisLoading(false),
      )
    }
    return () => setIsKpisLoading(false)
  }, [fetchForecastKpis, projectionId, dataPhase.key, forecastInstanceDetail])

  useEffect(() => {
    if (tabKey !== PROJECTION.PLANNING_KEYS.TRANSACTIONAL_KEY) return
    if (!_.isEmpty(forecastInstanceDetail)) {
      setIsTableNestedLoading(true)
      fetchDataNestedTableForecastByPhase({
        step: dataPhase.key,
        period_id: periodId,
        concept_id: forecastInstanceDetail?.concept_id,
        forecast_kpis_id: projectionId,
        page: tablePagination.page,
      }).then(() => setIsTableNestedLoading(false))
      fetchDateBlockingByForecast({
        concept_id: forecastInstanceDetail?.concept_id,
        forecast_kpis_id: projectionId,
        page: tablePagination.page,
        period_id: periodId,
        step: dataPhase.key,
      }).then((resp) => {
        setDateBlocking(resp.payload.data.month_blocking ?? [])
      })
    }
    return () => setIsTableNestedLoading(false)
  }, [
    fetchDataNestedTableForecastByPhase,
    projectionId,
    periodId,
    dataPhase.key,
    forecastInstanceDetail,
    tablePagination.page,
    tabKey,
  ])

  useEffect(() => {
    if (tabKey !== PROJECTION.PLANNING_KEYS.PERCENTAGE_KEY) return
    if (!_.isEmpty(forecastInstanceDetail) && businessRuleId) {
      setIsTableNestedLoading(true)
      fetchDataRdnPercentageByForecast({
        concept_id: forecastInstanceDetail?.concept_id,
        forecast_kpis_id: projectionId,
        page: tablePagination.page,
        period_id: periodId,
        step: dataPhase.key,
        business_rule_id: businessRuleId,
      }).then(() => setIsTableNestedLoading(false))
    }
    return () => setIsTableNestedLoading(false)
  }, [
    fetchDataRdnPercentageByForecast,
    projectionId,
    periodId,
    dataPhase.key,
    forecastInstanceDetail,
    tabKey,
    businessRuleId,
  ])

  useEffect(() => {
    fetchRulesList({ period_id: periodId, type: 'forecast', pk: projectionId }).then((resp) => {
      const result = resp.payload.data
      const sortedRules = result.results.sort((a, b) => {
        if (a.isApplied === b.isApplied) {
          return 0
        }
        return a.isApplied ? -1 : 1
      })
      if (!_.isEmpty(sortedRules)) {
        setBusinessRuleId(sortedRules[0].id)
      }
    })
  }, [fetchRulesList, periodId, projectionId])

  const verifyPermission = (instance) => {
    const permission = [
      PROJECTION.GLOBAL_FORECAST.toString(),
      CONCEPTS.IDS.SALES_ID.toString(),
      CONCEPTS.IDS.EXPENSES_ID.toString(),
      CONCEPTS.IDS.COSTS_ID.toString(),
      CONCEPTS.IDS.HUMAN_RESOURCES_ID.toString(),
      CONCEPTS.IDS.OTHER_5_ID.toString(),
      CONCEPTS.IDS.OTHER_6_ID.toString(),
    ]
    if (permission.includes(instance?.concept_id.toString())) {
      return isUserAllowed(
        FORECAST__CONCEPT.AMB[instance?.concept_id][
          !_.isEmpty(instance) && instance?.step.toUpperCase()
        ],
      )
    } else if (extraConcept) {
      return true
    }

    return false
  }

  const isFinished = forecastInstanceDetail?.step !== dataPhase.key
  const dataPeriod = periodList.find((el) => el.id === parseInt(periodId))
  const canUserABM = !_.isEmpty(forecastInstanceDetail)
    ? verifyPermission(forecastInstanceDetail)
    : true

  const reloadTableNested = () => {
    setIsTableNestedLoading(true)
    setIsKpisLoading(true)
    Promise.all([
      fetchForecastKpis({ step: dataPhase.key, forecast_kpis_id: projectionId }),
      fetchDataNestedTableForecastByPhase({
        step: dataPhase.key,
        period_id: periodId,
        concept_id: forecastInstanceDetail?.concept_id,
        forecast_kpis_id: projectionId,
        page: tablePagination.page,
      }),
    ]).then(() => {
      setIsTableNestedLoading(false)
      setIsKpisLoading(false)
    })
  }

  const onClose = () => {
    setIsDeletingAll(false)
    setShowDeleteAll(false)
  }

  const onSuccessUploadFile = (response) => {
    notification.success({
      message: t('FEEDBACK_EVERYTHING_READY'),
      description: (
        <>
          <span>{t('FEEDBACK_UPLOAD_DATA_FILE_SUCCESS')}</span>
          <span
            onClick={() => history.push('/procesosdecarga')}
            style={{ color: '#0047ba', cursor: 'pointer' }}
          >
            {t('LABEL_PROCESS_UPLOAD')}
          </span>
        </>
      ),
      duration: 0,
    })
    // GA.event(GA.ACTIONS.FILE_UPLOAD_REAL);
    reloadTableNested()
  }

  const onFailUploadFile = (error) => {
    notification.error({
      key: 'error_file',
      message: t('FEEDBACK_DEFAULT_ERROR'),
      description: generateMessageError(error),
      duration: 0,
    })
    // GA.event(GA.ACTIONS.FILE_UPLOAD_REAL_ERROR);
  }

  const onChangeTypeOfLoad = (e) => {
    setTypeOfLoad(e.target.value)
  }

  // console.log(dateBlockingByForecast)

  const reloadTablePercentage = () => {
    fetchDataRdnPercentageByForecast({
      concept_id: forecastInstanceDetail?.concept_id,
      forecast_kpis_id: projectionId,
      page: tablePagination.page,
      period_id: periodId,
      step: dataPhase.key,
      business_rule_id: businessRuleId,
    })
  }

  return (
    <>
      <Row gutter={[24, 16]}>
        <Col span={24}>
          <UserActionsPhases
            onConfirmPopConfirm={() => {
              setShowPopConfirmSend(false)
              renderFunctionSend({
                step: forecastInstanceDetail?.step,
                setILoading: setIsDataLoading,
                setNextPhase: () => setNextPhase({ forecast_kpis_id: projectionId, status_budget }),
                closing: () =>
                  closingForecast({ forecast_kpis_id: projectionId, is_closing: true }),
                onRefetch,
                onClose: () => setIsDataLoading(false),
              })
            }}
            onCancelPopconfirm={() => setShowPopConfirmSend(false)}
            showPopConfirmSend={showPopConfirmSend}
            onClickSend={() => setShowPopConfirmSend(true)}
            buttonName={renderButtonSendName({
              step: forecastInstanceDetail?.step,
              dataPhasekey: dataPhase.key,
              is_closing: forecastInstanceDetail?.is_closing,
              buttonName: dataPhase.buttonName,
            })}
            disabled={isFinished || forecastInstanceDetail?.is_closing || !canUserABM}
            dataPhase={dataPhase}
            loading={isDataLoading}
            is_closing={forecastInstanceDetail?.is_closing}
            buttonUploadData={
              <ButtonUploadData
                onClickFile={() => setShowUploadModal(true)}
                onClickSpreadsheets={() => setShowSpreadsheetsModal(true)}
                disabled={
                  isFinished || forecastInstanceDetail?.is_closing || !canUserABM || isDataLoading
                }
              />
            }
            onClickActivity={() =>
              history.push(
                `/forecast/${periodId}/${forecastId}/${forecastInstanceDetail?.concept_name}/${dataPhase.key}/actividad/${projectionId}?type=${PROJECTION.FORECAST}`,
              )
            }
            onClickApply={() => setShowDrawerRules(true)}
            loadingDelete={isDeletingAll}
            visible={showDeleteAll}
            onCancelDeleteAll={() => setShowDeleteAll(false)}
            onConfirmDeleteAll={() =>
              handleDeleteAll({
                setIsLoading: setIsDeletingAll,
                deleteAll: () => deleteAllTransaction(projectionId, dataPhase.key),
                onRefetch: () =>
                  fetchDataNestedTableForecastByPhase({
                    step: dataPhase.key,
                    period_id: periodId,
                    concept_id: forecastInstanceDetail?.concept_id,
                    forecast_kpis_id: projectionId,
                    page: tablePagination.page,
                  }),
                onClose,
              })
            }
            onClickDeleteAll={() => setShowDeleteAll(true)}
            onClickCreate={() => setShowCreateModal(true)}
            hasCreate={canUserABM}
            extraButtons={{
              module_name: 'forecast',
              concept: forecastInstanceDetail?.concept_id,
              submodule_name: projectionId,
            }}
            type="forecast"
          />
        </Col>
        <Col span={24}>
          <PhaseKpis
            kpiLoading={isKpisLoading}
            dataKpis={forecastKPIs}
            userDetail={loggedUser}
            setData={setDataComments}
            comments={dataComments}
            onRefetchComments={() => fetchCommentsList({ module: `${key}-${dataPhase.key}` })}
            loading={isLoading}
            phase={dataPhase.key}
          />
        </Col>
        <Col span={24}>
          <TabsTables
            tabKey={tabKey}
            setTabKey={setTabKey}
            canUserABM={canUserABM}
            forecastInstanceDetail={forecastInstanceDetail}
            onConfirmDeletSelection={() => handleDeleteSelection()}
            isDataLoading={isDataLoading}
            dataPhase={dataPhase}
            dataPeriod={dataPeriod}
            isTableNestedLoading={isTableNestedLoading}
            reloadTableNested={reloadTableNested}
            isFinished={isFinished}
            businessRuleId={businessRuleId}
            setBusinessRuleId={setBusinessRuleId}
            reloadTablePercentage={reloadTablePercentage}
            setData={setDataComments}
            comments={dataComments}
            onRefetchComments={() => fetchCommentsList({ module: `${key}-${dataPhase.key}` })}
            loading={isLoading}
          />
        </Col>
      </Row>
      <ProjectionUploadModal
        title={t('ACTION_UPLOAD_DATA')}
        visible={showUploadModal}
        onCancel={() => setShowUploadModal(false)}
        uploadFile={(fileList, typeOfLoad) =>
          uploadFileByPhaseForecast(fileList, {
            forecast_kpis_id: projectionId,
            concept_id: forecastInstanceDetail?.concept_id,
            type_file: 'forecast',
            type_of_load: typeOfLoad,
          })
        }
        onSuccessUpload={onSuccessUploadFile}
        onFailUpload={onFailUploadFile}
        downloadTemplateFile={() =>
          downloadControlTemplateXLS(
            t('FORECAST_TEMPLATE_NAME', {
              name: forecastInstanceDetail?.name,
              periodName: dataPeriod.name,
              date: moment().format('lll'),
            }),
            { concept_id: forecastInstanceDetail?.concept_id, period_id: periodId },
          )
        }
        enabledTypeOfLoad={true}
        extraInformativeCurrency={true}
      />
      <SpreadsheetsModal
        visible={showSpreadsheetsModal}
        onCancel={() => {
          setShowSpreadsheetsModal(false)
          setTypeOfLoad(PROJECTION.TYPE_UPLOAD_OPTION_DEFAULT)
        }}
        onRefetch={reloadTableNested}
        dataSpreadsheets={{
          type_load: 'forecast_update',
          params: {
            forecast_kpis_id: projectionId,
            concept_id: forecastInstanceDetail?.concept_id,
            type_of_load: typeOfLoad,
          },
        }}
        onChangeTypeOfLoad={onChangeTypeOfLoad}
        typeOfLoad={typeOfLoad}
        enabledTypeOfLoad={true}
        periodId={periodId}
        conceptId={forecastInstanceDetail?.concept_id}
      />
      <ToolsDrawer
        onRefetchDataTable={reloadTableNested}
        visible={showDrawerRules}
        onClose={() => setShowDrawerRules(false)}
        globalProjectionId={projectionId}
        type="forecast"
        onRefetch={onRefetch}
        applyRule={(ruleId) =>
          applyRuleinstanceForecast(projectionId, ruleId, {
            concept_id: forecastInstanceDetail?.concept_id,
            period_id: periodId,
            step: forecastInstanceDetail?.step,
          })
        }
        unApplyRule={(ruleId) =>
          unApplyRuleInstanceForecast(projectionId, ruleId, {
            concept_id: forecastInstanceDetail?.concept_id,
            period_id: periodId,
            step: forecastInstanceDetail?.step,
          })
        }
        disabled={isFinished || forecastInstanceDetail?.is_closing || !canUserABM}
        typeModule={GENERAL.MODULES_KEYS.MODULE_FORECAST}
        moduleId={projectionId}
        periodId={periodId}
        hasRule={true}
      />

      <CreateTransactionModal
        title={t('ACTION_CREATE_TRANSACTION')}
        dataPeriod={dataPeriod}
        visible={showCreateModal}
        onCancel={() => setShowCreateModal(false)}
        onRefetch={reloadTableNested}
        onCreateTransaction={(values) =>
          massiveCreateForecastTransaction({
            ...values,
            period_id: periodId,
            concept_id: forecastInstanceDetail?.concept_id,
            type_sync_data: typeOfLoad,
            forecast_kpis_id: projectionId,
          })
        }
        dateBlocking={dateBlocking}
        onChangeTypeOfLoad={onChangeTypeOfLoad}
        typeOfLoad={typeOfLoad}
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  forecastKPIs: forecast.selectors.getForecastKpis(state),
  periodList: planning.selectors.getPeriodList(state),
  loggedUser: login.selectors.getWhoAmI(state),
  commentsList: configuration.selectors.getCommentsList(state),
})

const mapDispatchToProps = {
  setNextPhase: forecast.actions.setNextPhase,
  closingForecast: forecast.actions.closingForecast,
  fetchForecastKpis: forecast.actions.fetchForecastKpis,
  fetchDataNestedTableForecastByPhase: forecast.actions.fetchDataNestedTableForecastByPhase,
  fetchExpandNestedTableRow: forecast.actions.fetchExpandNestedTableRow,
  downloadControlTemplateXLS: control.actions.downloadControlTemplateXLS,
  uploadFileByPhaseForecast: forecast.actions.uploadFileByPhaseForecast,
  applyRuleinstanceForecast: forecast.actions.applyRuleinstanceForecast,
  unApplyRuleInstanceForecast: forecast.actions.unApplyRuleInstanceForecast,
  massiveCreateForecastTransaction: forecast.actions.massiveCreateForecastTransaction,
  fetchAllDimensions: configuration.actions.fetchAllDimensions,
  fetchDateBlockingByForecast: forecast.actions.fetchDateBlockingByForecast,

  // TODO: NEW ENDPOINTS FOR REVAMP TABLE
  fetchDataRdnPercentageByForecast: forecast.actions.fetchDataRdnPercentageByForecast,
  fetchRulesList: configuration.actions.fetchRulesList,
  deleteAllTransaction: forecast.actions.deleteTransactionFilterByForecast,
  fetchCommentsList: configuration.actions.fetchCommentsList,
}

export default connect(mapStateToProps, mapDispatchToProps)(BaseForecast)
