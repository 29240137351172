import React from 'react'
import { Row, Col } from 'antd'
import { useTranslation } from 'react-i18next'
import { KPIValue, Typography } from 'modules/core/components'
import { REPORT } from 'modules/core/constants'
import numeral from 'numeral'
// import emptyImage from 'assets/images/compositions/empty-card.svg';

const ProjectionListKPI = ({
  type,
  emptyState: { title, description },
  // image = null,
  hasData,
  dataKpi,
}) => {
  const { t } = useTranslation()

  const renderEmptyKpis = () => (
    <Row align="middle">
      <Col span={24}>
        <Typography.Body level={2}>
          {title ? title : t('EMPTY_STATE_NO_DATA_TITLE')}
        </Typography.Body>
        <br />
        {description && (
          <Typography.Body level={3} type="secondary">
            {description}
          </Typography.Body>
        )}
      </Col>
      {/* <Col span={4} offset={2}>
        <img alt="img" height="100" src={image ? image : emptyImage} />
      </Col> */}
    </Row>
  )

  const renderKpis = () => (
    <Row align="middle">
      <Col span={24}>
        <KPIValue
          size="large"
          fullValue={dataKpi && numeral(dataKpi).format('0,0.00')}
          value={dataKpi ? numeral(dataKpi).format('0[.]0a') : '-'}
          prefix={'$'} //ver cuando este multimoneda
        />
        {type === REPORT.REPORTS_KEYS.PNL_KEY && (
          <Typography.Body title="YTD" level={3} type="secondary">
            YTD
          </Typography.Body>
        )}
      </Col>
    </Row>
  )

  return (hasData &&
    (type === REPORT.REPORTS_KEYS.PNL_KEY || type === REPORT.REPORTS_KEYS.FNL_KEY)) ||
    dataKpi //ver si es necesario
    ? renderKpis()
    : renderEmptyKpis()
}

export default ProjectionListKPI
