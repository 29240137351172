const general = {
  'HI': '¡Hi, {{name}}!',
  'ES': 'Español',
  'EN': 'English',
  'YES': 'Yes',
  'NO': 'No',

  // Layout
  'PROFILE': 'My profile',
  'SESSION': 'Session',
  'MENU': 'Menu',
  'LOGIN': 'Log in',
  'SIGN_OFF': 'Sign off',
  'SIGN_OFF_CONFIRM_MESSAGE': `Is your workday coming to a close? Great! It's time to unwind and recharge. If you're ready to sign off, let's bid farewell with a cheerful "See you soon!`,
  'NO_NOTIFICATIONS_MESSAGE': 'There are no new notifications.',

  'NOT_FOUND_SCREEN_TITLE': 'This page is not available',
  'NOT_FOUND_SCREEN_DESCRIPTION':
    'The link you entered may be broken or the page has been removed.',

  // Call to actions
  'ACTION_ACEPT': 'Accept',
  'ACTION_ACTIVITIES': 'See activities',
  'ACTION_ADJUST': 'Adjust',
  'ACTION_ANULATE': 'Cancel',
  'ACTION_APPLY': 'Apply',
  'ACTION_APPROVE': 'Approve ',
  'ACTION_CANCEL': 'Cancel',
  'ACTION_CLEAR': 'Clear',
  'ACTION_CLOSE': 'Close',
  'ACTION_CONFIGURATE': 'Set up',
  'ACTION_COPY': 'Create copy',
  'ACTION_CREATE': 'Create',
  'ACTION_CREATE_NEW': 'Create new',
  'ACTION_DEFINE': 'Define',
  'ACTION_DELETE': 'Delete',
  'ACTION_DELETE_ROW': 'Delete row',
  'ACTION_DELETE_ALL': 'Delete all',
  'ACTION_DELETE_FILTERED': 'Delete filtered',
  'ACTION_DISTRIBUTE': 'To distribute',
  'ACTION_DUPLICATE': 'Duplicate',
  'ACTION_DOWNLOAD': 'Discharge',
  'ACTION_DOWNLOAD_TABLE': 'Download chart',
  'ACTION_DOWNLOAD_TEMPLATE': 'Download template',
  'ACTION_EDIT': 'Edit',
  'ACTION_EDIT_NAME': 'Edit name',
  'ACTION_ENTER': 'More actions',
  'ACTION_EXIT': 'Exit',
  'ACTION_FINISH': 'Finish',
  'ACTION_MORE': 'More actions',
  'ACTION_NOTIFICATE': 'Notify',
  'ACTION_OPEN_DETAIL': 'See detail',
  'ACTION_PUBLISH': 'Publish',
  'ACTION_REMOVE': 'Remove',
  'ACTION_RETURN': 'Return',
  'ACTION_RETURN_ANYWAY': 'Go back',
  'ACTION_SAVE': 'Save',
  'ACTION_SAVE_CHANGES': 'Save changes',
  'ACTION_SEARCH': 'Search',
  'ACTION_SELECT': 'Select',
  'ACTION_STAGE': 'Simulate scenario',
  'ACTION_START': 'Start',
  'ACTION_STORE': 'File',
  'ACTION_UPLOAD': 'Upload',
  'ACTION_UPLOAD_FILE': 'Upload file',
  'ACTION_UPLOAD_START': 'Start upload',
  'ACTION_UNDERSTAND': 'Understood',
  'ACTION_NEXT': 'Next',
  'ACTION_DELETE_SELECTION': 'Remove selection',
  'ACTION_DELETE_PAGE': 'Remove Page',
  'ACTION_MODIFY': 'Modify',
  'ACTION_UNDO': 'Undo',
  'ACTION_GO_TO_DETAIL': 'Go to detail',
  'ACTION_UNAPPLY': 'Unapply',
  'ACTION_VIEW': 'View',
  'ACTION_UPDATE_DATA': 'Update data',
  'ACTION_ANALYTICS_PATCH': 'See analytics',
  'ACTION_FIX': 'Pin up',
  'ACTION_KEEP': 'Keep',
  'ACTION_SHOW_COMMENTS': 'Show comments',
  'ACTION_COMMENT': 'Comment',
  'ACTION_READ_MORE': 'Read more',
  'ACTION_HIDE': 'Hide',
  'ACTION_SHOW': 'Show',
  'ACTION_REPLICATE': 'Replicate',
  'ACTION_REPLICATE_VALUE': 'Replicate value',
  'ACTION_EXPORT': 'Export',
  'ACTION_DOWNLOAD_PPT': 'Download Power Point',
  'ACTION_SORT_ASC': 'Sort ascending',
  'ACTION_SORT_DESC': 'Sort descending',
  'ACTION_GO': 'Go',
  'ACTION_SKIP': 'Skip',
  'ACTION_LOAD_EXCHANGE_RATE': 'Upload exchange rate',
  'ACTION_GO_TO_CONFIGURE': 'Go to configuration',
  'ACTION_CONFIRM': 'Confirm',
  'ACTION_ACTIVATE': 'Activate',
  'ACTION_DEACTIVATE': 'Deactivate',
  'ACTION_CONNECT': 'Connect',
  'ACTION_DISCONNECT': 'Disconnect',
  'ACTION_UPLOAD_DATA': 'Upload data',
  'ACTION_ASSOCIATE_DATA': 'Associate data',
  'ACTION_PREVIOUS': 'Previous',
  'ACTION_BEGIN': 'Start',
  'ACTION_GROUP': 'Group',
  'ACTION_SEND': 'Send',
  'ACTION_ADD_NEW': 'Add new',
  'ACTION_SORT': 'Sort',
  'ACTION_CREATE_TRANSACTION': 'Create transaction',
  'ACTION_PREDETERMINED_PIN_UP': 'Set as default',
  'ACTION_ADD_FAVORITE': 'Add as favorite',
  'ACTION_CHANGE_COLOR': 'Change color',
  'ACTION_SOLVE': 'Solve',
  'ACTION_REPLY': 'Reply',
  'ACTION_NEW_THREAD': 'New thread',

  //States
  'STATE_TAG_APPROVED': 'Approved',
  'STATE_TAG_CANCELED': 'Canceled',
  'STATE_TAG_EJECUTION': 'In action',
  'STATE_TAG_ELABORATION': 'In progress',
  'STATE_TAG_FINISHED': 'Finished',
  'STATE_TAG_PUBLISHED': 'Published',
  'STATE_TAG_STARTED': 'Started',
  'STATE_TAG_ACTIVE': 'Active',
  'STATE_TAG_INACTIVE': 'Inactive',
  'STATE_TAG_UNDEFINED': 'Undefined',
  'STATE_TAG_FINISHED_SUCCESS': 'Successfully finished',
  'STATE_TAG_CONFIGURED': 'configurated',
  'STATE_TAG_NOT_CONFIGURED': 'Not configured',
  'STATE_TAG_LOADED': 'Loaded',
  'STATE_TAG_PROCESSING': 'Processing',
  'STATE_TAG_PENDING': 'Pending',
  'STATUS_DATE_APPROVED': 'Approve',
  'STATUS_DATE_PUBLISHED': 'Publish',
  'STATUS_TAG_CLOSED': 'Closed',
  'LOADING_STATUS': 'Upload status',
  'LOADING_STATUS_ERROR': 'Error',
  'LOADING_STATUS_SUCCESS': 'Completed',

  //BUDGET_STATUS
  // TODO: Definir todos los estados, en acuerdo con back y UI
  'in_elaboration': 'In progress',
  'making': 'In progress',
  'approved': 'Approved',
  'published': 'Published',
  'initiated': 'Initiated',

  //Entities labels
  'LABEL_ACTIVITY': 'Activity',
  'LABEL_ACTIVITIES': 'Activities',
  'LABEL_ACCOUNTS': 'Account Charts',
  'LABEL_ADJUSTMENTS': 'Adjustments',
  'LABEL_ANALYSIS': 'Analysis',
  'LABEL_BUDGET': 'Budget',
  'LABEL_BUDGETS': 'Budgets',
  'LABEL_BUDGET_TYPE': 'Budget concept',
  'LABEL_CONFIG': 'Settings',
  'LABEL_CONTROL': 'Actuals',
  'LABEL_DETAIL': 'Detail',
  'LABEL_DIMENSIONS': 'Dimensions',
  'LABEL_DISTRIBUTIONS': 'Distributions',
  'LABEL_VALUES_DIMENSION': 'Dimension values',
  'LABEL_EXPENSES': 'Bills',
  'LABEL_FORECAST': 'Forecast',
  'LABEL_PERIODS': 'Periods',
  'LABEL_PERIOD': 'Period',
  'LABEL_PLANNING': 'Planning',
  'LABEL_PROFILE': 'Profile',
  'LABEL_REPORTS': 'Reports',
  'LABEL_REPORT': 'Report',
  'LABEL_OTHERS': 'Others',
  'LABEL_SALES': 'Sales',
  'LABEL_USERS': 'Users',
  'LABEL_USER': 'User',
  'LABEL_SECURITY': 'Security',
  'LABEL_ROLES': 'Roles',
  'LABEL_ROLE': 'Role',
  'LABEL_VARIABLE': 'Variable',
  'LABEL_VARIABLES': 'Variables',
  'LABEL_MODIFIERS': 'Modifiers',
  'LABEL_PROJECTION_ELEMENTS': 'Projection elements',
  'LABEL_FORMULAS': 'Formulas',
  'LABEL_FORMULA': 'Formula',
  'LABEL_BUSINESS_RULES': 'Business rules',
  'LABEL_BUSINESS_RULE': 'Business rule',
  'LABEL_FACTOR': 'Factor',
  'LABEL_FACTORS': 'Factors',
  'LABEL_GLOBAL_BUDGET': 'Global budget',
  'LABEL_GLOBAL_FORECAST': 'Global forecast',
  'LABEL_NEW': 'New',
  'LABEL_CONVENTIONAL_LOAD': 'Conventional load',
  'LABEL_FLAT_LOAD': 'Flat load',
  'LABEL_RESUME': 'Summary',
  'LABEL_FINANCIAL_GUIDELINES': 'Financial guidelines',
  'LABEL_REAL': 'Actuals',
  'LABEL_NAME_FILE': 'File name',
  'LABEL_FILE_TYPE': 'File type',
  'LABEL_LOAD_DETAILS': 'File upload details',
  'LABEL_LOAD_DETAIL': 'File upload detail',
  'LABEL_LOAD_DATA': 'About data',
  'LABEL_LOAD_ASSOCIATION_DATA': 'Association account-concept',
  'LABEL_EXCHANGE_RATE': 'Exchange rate',
  'LABEL_CURRENCY': 'Currency',
  'LABEL_MULTICURRENCY': 'Multicurrency',
  'LABEL_RATIO': 'Ratio',
  'LABEL_FINANCIAL': 'Financial',
  'LABEL_ECONOMIC': 'Economic',
  'LABEL_STATUS_CHANGE': 'Status change',
  'LABEL_DESTINATION_PROJECTION': 'Destination projection',
  'LABEL_MY_DATA': 'My data',
  'LABEL_COSTS': 'Costs',
  'LABEL_HUMAN_RESOURCES': 'Human Resources',
  'LABEL_CLOSING': 'Closure',
  'LABEL_VISUALIZATION_TABLE': 'Visualization Table',
  'LABEL_TUTORIALS': 'Tutorials',
  'LABEL_HELP': 'Help',
  'LABEL_USER_MANUAL': 'User manual',
  'LABEL_ROLES_AND_USERS': 'Roles and users',
  'LABEL_EXAMPLE_TEMPLATE': 'Example template',
  'LABEL_INTEGRATION': 'Integrations',
  'LABEL_STANDAR_TABLE': 'Standard table',
  'LABEL_NESTED_TABLE': 'Nested Table',
  'LABEL_NAME_CHATBOT': 'Plika AlphaBot',
  'LABEL_FILE': 'File',
  'LABEL_PROCESS_START': 'Process start',
  'LABEL_PROCESS_END': 'End of process',
  'LABEL_PROCESS_UPLOAD': 'Process Upload',
  'LABEL_PROJECTION': 'Projection',
  'LABEL_DASHBOARD': 'Dashboard',
  'LABEL_SOCIETIES': 'Companies',
  'LABEL_QUESTION': 'Questions configuration',
  'LABEL_OR': 'or',
  'LABEL_TOOLS': 'Tools',
  'LABEL_DELIMITERS': 'Delimiters',
  'LABEL_DELIMITER_THOUSANDS': 'Delimiters thousands',
  'LABEL_DELIMITER_DECIMAL': 'Delimiters decimal',
  'LABEL_PLANNING_INSTANCE': 'Planning instantce',
  'LABEL_FORECAST_INSTANCE': 'Forecast instance',
  'LABEL_REPORT_INSTANCE': 'Report instnace',
  'LABERL_PREVIUS_REAL': 'Actuals',
  'LABEL_COMMENTS': 'Comments',
  'LABEL_ACTIVE': 'Active',
  'LABEL_SOLVED': 'Solved',
  'LABEL_TYPE_VALUES_REFERENCE': 'Values ​​in {{type}}',
  'LABEL_EXAMPLE': 'Example',

  'LABEL_ABBREVIATIONS': 'Abbreviations',
  'LABEL_ABBREVIATION_THOUSAND': 'Abbreviation thousands',
  'LABEL_ABBREVIATION_MILLION': 'Abbreviation million',
  'LABEL_ABBREVIATION_BILLION': 'Abbreviation billion',
  'LABEL_ABBREVIATION_TRILLION': 'Abbreviation trillion',

  //Fields
  'REQUIRED_FIELD': 'Mandatory field',
  'REQUIRED_FIELD_CHARACTERS': 'Cannot exceed {{cant}} characters',
  'FIELD_ALL': 'All',
  'FIELD_ACCOUNT': 'Account',
  'FIELD_ACCOUNTS': 'Accounts',
  'FIELD_ACTION': 'Action',
  'FIELD_ACTIONS': 'Actions',
  'FIELD_AMOUNT': 'Amount',
  'FIELD_BEHAVIOR': 'Behaviour',
  'FIELD_CODE': 'Code',
  'FIELD_COMPARISONS': 'Comparisons',
  'FIELD_SEGMENTED': 'Segmented',
  'FIELD_CONCEPT': 'Concept',
  'FIELD_PRICE': 'Price',
  'FIELD_COUNT': 'Quantity',
  'FIELD_DATE': 'Date',
  'FIELD_CREATION_DATE': 'Creation date',
  'FIELD_CREATOR_INFO': 'Created by {{username}}',
  'FIELD_DEADLINE': 'Upload deadline',
  'FIELD_DESCRIPTION': 'Description',
  'FIELD_DIMENSION': 'Dimension',
  'FIELD_DIMENSION_DIMENSIONS': 'Dimension/s',
  'FIELD_DIMENSION_VALUES': 'Dimension value(s)',
  'FIELD_EMAIL': 'Email',
  'FIELD_END_DATE': 'End month',
  'FIELD_IMPACT': 'Impact',
  'FIELD_NAME': 'Name',
  'FIELD_TIME': 'Time',
  'FIELD_OBSERVATIONS': 'Observations',
  'FIELD_ORDER': 'Order',
  'FIELD_ORIGINAL': 'Original',
  'FIELD_PERCENTAGE': 'Percentage',
  'FIELD_ABSOLUTE': 'Absolute',
  'FIELD_START_DATE': 'Start month',
  'FIELD_STATUS': 'Status',
  'FIELD_SURNAME': 'Last name',
  'FIELD_TOTAL': 'Total',
  'FIELD_TYPE': 'Type',
  'FIELD_DATA_TYPE': 'Data type',
  'FIELD_VALUE': 'Value',
  'FIELD_VALUE_TYPE': 'Value type',
  'FIELD_CANCELLATION': 'Cancellation',
  'FIELD_YEAR_MONTH': 'Year/Month',
  'FIELD_MOVEMENTS': 'Movements',
  'FIELD_ASSOCIATED_CONCEPT': 'Associated concept',
  'FIELD_ASSOCIATED_CONCEPTS': 'Associated concepts',
  'FIELD_ASSOCIATED_CONCEPT_CONCEPTS': 'Associated concept(s)',
  'FIELD_EVOLUTION': 'Monthly evolution',
  'FIELD_ASSOCIATED_DIMENSIONS': 'Associated dimensions',
  'FIELD_ASSOCIATED_DIMENSION': 'Associated dimension',
  'FIELD_COMMENT': 'Comment',
  'FIELD_ATENTION': 'Attention!!',
  'FIELD_IMPORTANT': 'Important!',
  'FIELD_LEVELS': 'Levels',
  'FIELD_LEVEL': 'Level',
  'FIELD_ACCOUNTS_IMPACTED': 'Impacted account(s)',
  'FIELD_TOTAL_AMOUNT': 'Total amount',
  'FIELD_TOTAL_LINES': 'Total lines',
  'FIELD_PREDETERMINED_BUDGET': 'Predetermined budget',
  'FIELD_APPLIED': 'Applied',
  'FIELD_UNAPPLIED': 'Unapplied',
  'FIELD_LINE_REPORT': 'P&L Line',
  'FIELD_LOAD_TYPE': 'Load type',
  'FIELD_RED': 'Red',
  'FIELD_PRINCIPAL': 'Major',
  'FIELD_SECONDARY': 'Secondary',
  'FIELD_NUMBER': 'Number',
  'FIELD_CURRENCY_TYPE': 'Type of currency',
  'FILED_ORIGIN_CURRENCY': 'Origin currency',
  'FIELD_FUNCTIONAL_CURRENCY': 'Functional currency',
  'FIELD_ORIGIN_CURRENCIES': 'Origin currencies',
  'FIELD_FULL_VALUE': 'Full value',
  'FIELD_VARIATION': 'Variation',
  'FIELD_LAST_UPDATED': 'Last update',
  'FIELD_DIMENSIONS_AMOUNT': 'Amount of dimensions',
  'FIELD_TRANSACTION': 'Transaction',
  'FIELD_CONNECTOR': 'Connector',
  'FIELD_SOURCE': 'Source',
  'FIELD_TABLE': 'Table',
  'FIELD_CHART': 'Chart',
  'FIELD_TITLE': 'Title',
  'FIELD_CHART_TYPE': 'Graph type',
  'FIELD_ELMENT_TYPE': 'Type of element',
  'FIELD_SIZE': 'Size',
  'FIELD_REPORT_LINE': 'Report line',
  'FIELD_QUESTION': 'Question',
  'FIELD_DOCUMENTATION': 'Documentation',
  'FIELD_AUTHENTICATION': 'Authentication',
  'FIELD_FILE_S3_UPLOAD': 'Uplaod S3 file',
  'FIELD_CUSTOM_BOTTOM': 'Custom buttons',
  'FIELD_FORMAT': 'Format',
  'FIELD_QR_BUTTOM': 'QR code',
  'FIELD_PREDETERMINED': 'Predeterminded',
  'FIELD_FAVORITE': 'Favorite',
  'FIELD_BEST_SELLERS': 'Higher %',
  'FIELD_LESS_SOLD': 'Lower %',
  'FIELD_REPORT_TYPE': 'Report type',
  'FIELD_TAB_TRANSACTIONAL': 'Transactional',
  'FIELD_TAB_PERCENTAGE': 'Percentage',
  'FIELD_DECIMALS': 'Decimals',
  'FIELD_VERSIONED': 'Versioned',
  'FIELD_SIGN': 'Sign',

  //Placeholders
  'ACCOUNT_PLACEHOLDER': 'Select an account',
  'DIMENSION_PLACEHOLDER': 'Select a dimension',
  'MONTH_PLACEHOLDER': 'Select a month',
  'UPLOAD_FILE_PLACEHOLDER': 'Select the file you want to upload',
  'VALUES_PLACEHOLDER': 'Select the values',
  'VALUE_PLACEHOLDER': 'Select a value',
  'ROW_SELECT_PLACEHOLDER': 'Select a row',
  'WRITE_COMMENT_PLACEHOLDER': 'Use @ to mention',
  'ENTER_AMOUNT_PLACEHOLDER': 'Enter amount',
  'ENTER_CODE_PLACEHOLDER': 'Enter code',
  'ENTER_NAME_PLACEHOLDER': 'Enter name',
  'ENTER_QUESTION_PLACEHOLDER': 'Enter question',
  'ENTER_QUANTITY_PLACEHOLDER': 'Enter quantity',
  'ENTER_PRICE_PLACEHOLDER': 'Enter price',
  'SELECT_CURRENCY_PLACEHOLDER': 'Select currency',

  //Feedbacks
  'FEEDBACK_LOADING': 'Loading...',
  'FEEDBACK_LOADING_CHANGES': 'Keeping changes ...',
  'FEEDBACK_DEFAULT_ERROR': 'We had a problem',
  'FEEDBACK_EDIT_REPORT_NAME_ERROR': 'This field may not be blank',
  'FEEDBACK_SAVE_CHANGES_SUCCES': 'Changes saved',
  'FEEDBACK_SAVE_CHANGES_FAIL_MESSAGE': 'Error when saving changes',
  'FEEDBACK_CHANGES_SAVED_SUCCESS': 'Changes were saved successfully',
  'FEEDBACK_SAVE_CHANGES_FAIL': 'A mistake occurred when trying to save the changes',
  'FEEDBACK_CREATION_FAIL': 'An error ocurred in creation. Please, try again.',
  'FEEDBACK_DELETE_FAIL': 'An error ocurred removing. Please, try again.',
  'FEEDBACK_UPLOAD_FILE_SUCCESS': "The {{configModule}}'s file has been uploaded successfully",
  'FEEDBACK_UPLOAD_PROJECITION_FILE_SUCCESS':
    'All the files have already been uploaded in the {{projectionType}}. {{descriptionType}}',
  'FEEDBACK_UPLOAD_FILE_WITH_ERRORS': 'The file was loaded but with some errors.',
  'FEEDBACK_UPLOAD_FILE_ERROR': 'There was an error when loading the file {{fileName}}.',
  'FEEDBACK_UPLOAD_FILE_HEADBOARD_ERROR': 'Invalid header',

  'FEEDBACK_WELLDONE': 'Great job!',
  'FEEDBACK_VERY_GOOD': 'Very good!',
  'FEEDBACK_ACTION_DEFAULT_ERROR': 'Retrying at a time for {{action}}',
  'FEEDBACK_DEFAULT_NOT_FOUND': 'We did not find results',
  'FEEDBACK_NOT_DATA_REQUEST': `¡Sorry! We couldn't find the requested data`,
  'FEEDBACK_DELETE_SELECTION_SUCCESS': 'The selection was deleted successfully',
  'FEEDBACK_DELETE_DATA_SUCCESS': 'The data was deleted successfully',
  'FEEDBACK_DELETE_SELECTION_ERROR': "We couldn't remove the selection. Please try again.",
  'FEEDBACK_DELETE_DATA_ERROR': "We couldn't remove the data. Please try again.",
  'FEEDBACK_CREATE_SUCCESS': 'Successful creation',
  'FEEDBACK_INPUT_PERCENTAGE': 'Assign percentage to the filtered value (s)',
  'FEEDBACK_EXPORT_CHARTS_AS_IMAGE_ERROR': 'An error occurred while exporting. Please try again.',
  'FEEDBACK_CONFIRM_ERROR_DESCRIPTION': 'A mistake occurred during confirmation.',
  'MESSAGE_PROCESS_STATUS_SUCCESS': 'Finished loading success',
  'MESSAGE_PROCESS_STATUS_PENDING': 'Pending process',
  'MESSAGE_PROCESS_STATUS_PROCESSING': 'Processing loaded information',
  'FEEDBACK_LOAD_DATA_SUCCESS': 'Data successfully loaded',
  'FEEDBACK_LOAD_DATA_ERROR': 'An error occurred when loading the data',
  'FEEDBACK_CONGRATULATIONS': 'Congratulations!',
  'FEEDBACK_DOWNLOAD_FILE_ERROR': 'An error occurred when downloading the file.',
  'FEEDBACK_DOWNLOAD_FILE_SUCCESS': 'Successful download',
  'FEEDBACK_DELETE_SUCCESS': 'The data successfully were deleted',
  'FEEDBACK_DUPLICATE_SUCCESS': 'The data has been duplicated sucessfully',
  'FEEDBACK_DUPLICATE_ERROR': 'An error occurred while duplicating the data',
  'FEEDBACK_OTHER_LOGIN_ERROR': 'An error occurred while trying to log in',
  'FEEDBACK_SELECT_ACCOUNT_ERROR': 'An error occurred while selecting the account to log in',
  'FEEDBACK_SETTING_SUCCESS': 'successful configuration',
  'FEEDBACK_SELECT_DATA_ERROR':
    'You must choose a maximum of 5 rows and 5 columns to display the information',
  'FEEDBACK_FUNCTION_ACTION_SUCCESS': 'Correct execution',
  'FEEDBACK_UPLOAD_AMOUNT_P_Q_ERROR':
    'You have entered data in all three columns: amount, price, and quantity. For the data to load correctly, you can only enter data in the amount column, or only in the price and quantity columns, or price and quantity individually.',
  'FEEDBACK_SEND_MAIL_SUCCESS': 'Mail sent succesfully',
  'FEEDBACK_SEND_MAIL_ERROR': 'An error occured while sending email',
  'FEEDBACK_GENERAL_DEFAULT': 'An error occurred while generating the action',
  'FEEDBACK_ERROR_500': 'Please contact the product manager',
  'FEEDBACK_UPLOAD_DATA_FILE_SUCCESS':
    'For more information on the progress of data loading, go to ',
  'FEEDBACK_EVERYTHING_READY': 'Everything ready!',
  'FEEDBACK_SPREADSHEET_TEXT': 'In the following link, you can find access to the spreadsheet',
  'FEEDBACK_DEFAULT_TEXT': 'To perform the following action, you can',
  'FEEDBACK_LOAD_TEXT': 'Action in progress...',

  //Info texts
  'TABLE_SETTING_MODAL_TITLE': 'Configure the appearance of the content and format of your table',
  'DISPLAY_MODAL_SETTINGS_TITLE': 'Display Preferences',
  'COLUMNS_SETTING_MODAL_PLACEHOLDER': 'Select the columns you want to see',
  'ALERT_NOT_ALLOWED': 'You have no permits to perform this action.',
  'ALERT_NULL_DATA': 'You still have data to load',
  'ALERT_INCOMPLETE_VALUES': 'Incomplete values',
  'EMPTY_STATE_DEFAULT_TITLE': "Let's do it!",
  'EMPTY_STATE_DRAG_FILE_TEXT': 'Click or drag the file into this area to upload.',
  'EMPTY_STATE_NO_DATA_TITLE': 'We still have no information to show',
  'EMPTY_STATE_UPLOAD_DEFAULT_DESCRIPTION': 'Start uploading your files',
  'UPLOAD_FILE_TITLE': 'Upload files to Plika',
  'INFO_UPLOAD_FILE_MODAL_DESCRIPTION':
    'In order to load, remember to build your file with the predetermined template',
  'INFO_UPLOAD_FILE_DOWNLOAD_ACTION': 'Download template',
  'INFO_UPLOAD_FILE_BODY_1':
    'Remember that all the files you upload must be created using the template we have provided for you and your team',
  'INFO_UPLOAD_FILE_BODY_2': "If you don't have it yet, you can download it from here.",
  'INFO_UPLOAD_TOOLTIP': 'You can do individual or massive uploads',
  'EXIT_MODAL_DESCRIPTION':
    'When you sign off, you will lose all the information that you have uploaded so far. Do you want to go out anyway?',
  'UPLOAD_ERRORS_FILE_DOWNLOAD_TEXT_1': 'Downlaod this',
  'UPLOAD_ERRORS_FILE_DOWNLOAD_TEXT_2': 'file',
  'UPLOAD_ERRORS_FILE_DOWNLOAD_TEXT_3': 'for more information regarding the errors that occured',
  'POPCONFIRM_DELETE_SELECTION_TITLE': 'Are you sure you want to eliminate this selection?',
  'POPCONFIRM_DELETE_DEFAULT_TITLE': 'Are you sure you want to eliminate {{name}}?',
  'POPCONFIRM_DELETE_FILTER_DATA': 'Are you sure you want to delete the filtered data?',
  'POPCONFIRM_DELETE_ALL_PAGES':
    'You are about to delete the entire contents of {{proyectionName}}. Are you sure you want to delete all pages?',
  'POPCONFIRM_EDIT_CANCEL': 'Are you sure you want to cancel the edition?',
  'TOOLTIP_NOT_APPROVE_TITLE': 'You must first publish the data to enable this action',
  'POPCONFIRM_SAVE_CHANGES': 'Are you sure you want to save the changes made?',
  'POPOVER_NOT_GLOBAL_BUDGET_FIXED': `There isn't fixed {{type}}. To view your data you must set it as default`,
  'POPOVER_DEFAULT_GLOBAL_BUDGET_DESCRIPTION':
    'These are your default budgets. To modify them, you should go to the global budget tab for planning and forecasting, respectively.',
  'POPOVER_NOT_DEFAULT_GLOBAL_BUDGET_DESCRIPTION': `There no default budgets. To visualize them you should go to the flap general planning and forecast budget respectively`,
  'TOOLTIP_CHECK_SOLVED_TITLE': 'Mark as resolved',
  'INPUT_ORDER_KPIS_TITLE': 'Kpi order',
  'MODAL_DELETE_CONFIRM_TEXT': 'Do you want to delete it anyway?',
  'UPLOAD_SCREEN_DESCRIPTION': 'Start uploading your files',
  'REFERENCE_DESCRIPTION': 'Values ​​in {{currencyname}}',
  'SELECT_OPTION_TITLE': 'How to continue?',
  'CONFIRM_ACTION_TITLE': 'Confirm action',
  'NEED_HELP': 'Do you need help with Plika?',
  'WRITE_SOMETHING_PLACEHOLDER': 'Write something...',
  'POPCONFIRM_REMOVE_DASHBOARD_ELEMENT': 'Are you sure you want to remove this element?',
  'TYPOGRAPHY_ENTER_VIEW_NAME': 'Enter a name for the view',
  'DATE_FORMAT_PLACEHOLDER': 'Date format',
  'INFORMATIVE_ALERT_UPLOAD_DATA_CURRENCY':
    'The data you enter will be reflected in the default currency',
  'EMPTY_NOT_SOLVED_COMMENTS_DESCRIPTION': 'There are no resolved comments yet',
  'EMPTY_NOT_COMMENTS_DESCRIPTION': 'No comments yet',
  'POPCONFIRM_REMOVE_COMMENT': 'Are you sure you want to delete this comment?',
  'DELETE_ALL_INFORMATIVE_TOOLTIP_TITLE': 'Delete all information on this card. {{extraText}}',
  'DELETE_SELECTION_INFORMATIVE_TOOLTIP_TITLE':
    'Removes the current selection information from the table. {{extraText}}',
  'DIMENSION_TABLE_INFORMATIVE_TOOLTIP_TITLE':
    'To choose the display preference in the table (by code or description) go to Settings > Dimensions > Preferences.',
  'SELECT_UNIT_MEASURE_INFORMATIVE_TOOLTIP_TITLE':
    'Select the unit of measure in which you want to view the table information.',
  'TAB_RULES_BUTTON_TOOLS_INFORMATIVE_TOOLTIP_TITLE':
    'Within this section you will be able to view and apply the business rules previously created in the Configuration module with the aim of loading individualized values ​​into them.',
  'FAVOURITE_ICON_INFORMATIVE_TOOLTIP_TITLE':
    'Allows you to add the dimension layout established in the table as a favorite.',
  'ADD_NEW_TRANSACTION_INFORMATIVE_TOOLTIP_TITLE':
    'It allows adding a new transaction in Plika by interface considering the data loading alternatives that exist in the tool.',
  'SELECT_TABLE_TYPE_INFORMATIVE_TOOLTIP_TITLE':
    'Within this selector you can define in which default formats to display the table information.',
  'TAB_CURRENCY_BUTTON_TOOLS_INFORMATIVE_TOOLTIP_TITLE':
    'Within this section you can establish the relationships between each of the configured currencies. This input will allow you to see the correctly converted information.',
  'EXTRA_TEXT_DELETE_INFORMATIVE_TOOLTIP_TITLE':
    'I would only apply that deletion for the projected months.',

  //Indicators
  'CORESPONDS': 'Corresponds to {{account}}',
  'DELTA': 'Delta',
  'REAL': 'Actuals',
  'SALES_TOTAL': 'Total sales',
  'EXPENSES_TOTAL': 'Total spends',
  'LAST_FORECAST': 'Last forecast',
  'REAL_LAST_PERIOD': 'Actuals last period',
  'REAL_LAST_YEAR': 'Actuals last year',
  'VARIATION_MONEY': 'Var {{prefix}}',
  'VARIATION_PERCENTAGE': 'Var %',
  'PREV_BUDGET': 'Budget last period',
  'LAST_PERIOD': 'Last period',

  // Traduccion de data_back
  'budget': 'Budget',
  'real': 'Actuals',
  'prev_real': 'Actuals last period',
  'last_forecast': 'Last forecast',
  // Ventas: 'Sales',
  // Gastos: 'Expenses',
  'init': 'Initial',
  'secuencial': 'Sequential',
  'over_amount': 'Over amount',
  'Activa': 'Active',
  'Inactiva': 'Inactive',
  'Cuenta': 'Account',
  'Sucursal': 'Subsidiary',
  'Centro de costos': 'Cost center',
  'OTROS': 'Others',

  'integer': 'Integer',
  'decimals': 'Decimals',
  'Thousands_millions': 'Thousands/millions',
  'auto': 'Automatic',
  'predefined': 'Predefined',
  'sales': 'Sales',
  'expenses': 'Expenses',
  'pnl': 'P&L',

  'DATE_AT_TIME': 'at',

  'DETAIL_TITLE': 'Detail {{name}}',
  'DUPLICATE_NAME': '{{name}} - duplicate ',
  'COPY_NAME': '{{name}} - copy ',

  'SHOW_FILTERS_MENU': 'Show filters',
  'HIDE_FILTERS_MENU': 'Hide filters',

  'GLOBAL_BUDGET_FILE_NAME': 'Plika - Global Budget',

  'DISPLAY_TABLE_SETTING': 'Visualization Numbers',

  // CHARTS
  'CHARTS_MODAL_SETTINGS_SUBTITLE': 'Configure the aspect for the visualization of your graphics',
  'FIELD_LABEL': 'Tags',
  'FIELD_LEGENDS': 'Legends',
  'LABEL_LAYOUT': 'Layout',
  'LABEL_DATA': 'Data',
  'LABEL_PADDING': 'Padding',
  'LABEL_POSITION_MODE': 'Disposition mode',
  'LABEL_DIMENSION_FILTER': 'Select the values ​​you want to reflect',
  'LABEL_GROUPED': 'Grouped',
  'LABEL_STACKED': 'Stacked',
  'LABEL_INNER': 'Inside',
  'LABEL_OUTER': 'Outside',
  'LABEL_NAME_VALUE': 'Name:value',
  'LABEL_FORMAT': 'Format',
  'LABEL_VALUE_TYPE': 'Worth',
  'LABEL_VALUE_POSITION': 'Location',
  'LABEL_FONT_SIZE': 'Font size',
  'CHARTS_VALUES_QUANTITY': 'Number of visible values',
  'CHARTS_TOOLTIP_INFO_LEGENDS':
    'You can display a legend at a time for each pie chart. If you want to see one particular, click on and see to the left of the same', //TODO: Pedir tooltip a UI
  'CHART_TYPE_PIE': 'Pie',
  'CHART_TYPE_BAR': 'Bars',
  'CHART_TYPE_LINE': 'Lines',
  'CHART_TYPE_SANKEY': 'Sankey',
  'CHART_SMALL_SIZE': 'Boy',
  'CHART_MEDIUM_SIZE': 'Medium',
  'CHART_LARGE_SIZE': 'Big',
  'CHART_EXPORT_AS_IMAGE_FILE_NAME': 'Chart {{chartTitle}} {{date}}.{{format}}',
  'CHART_DISPLAY_MODE_SETTINGS': 'Display mode',
  'CHART_SORTING_MODE_SETTINGS': 'Sorting mode',
  'CHART_PROVISION_SETTINGS': 'Provision',
  'CHART_VIEW_AREA_SETTINGS': 'View area',
  'CHART_VIEW_POINTS_SETTINGS': 'View points',
  'CHART_UPWARD_SETTINGS': 'Upward',
  'CHART_FALLING_SETTINGS': 'Falling',
  'LABEL_CHAT_BOT_START_CHAT': 'Select a question to start the chat',

  'LABEL_OPTION_TITLE_REPLACE': 'Replacing current data:',
  'LABEL_OPTION_TEXT_REPLACE':
    'Corresponding data for the months being updated will be removed, allowing the new data to become the primary source',
  'LABEL_OPTION_TITLE_ADD': 'Add to current data:',
  'LABEL_OPTION_TEXT_ADD':
    'Existing combinations matching new transactions are identified, and their values are added to the new operations. Also, transactions are added to combinations with a value of 0',
  'LABEL_OPTION_TITLE_OVERWRITE': 'Overwrite current data:',
  'LABEL_OPTION_TEXT_OVERWRITE':
    'Transactions with identical dimension values combinations are updated with new values, while those that do not match are created.',

  'TRANSACTION_DELETE_ACTION': 'Delete transaction',
  'TRANSACTION_ADD_NEW_ACTION': 'Add another transaction',
}

export default general
