import * as Sentry from '@sentry/react'

function sentryInit() {
  if (process.env.REACT_APP_SENTRY_DSN_FRONT) {
    console.log('Sentry initialized')
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN_FRONT,
      integrations: [Sentry.browserTracingIntegration()],
      release: process.env.REACT_APP_SENTRY_RELEASE,
      environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
      // Performance Monitoring
      tracesSampleRate: 1.0,
    })
  }
}

function setSentryUser(user) {
  if (process.env.REACT_APP_SENTRY_DSN_FRONT && user) {
    Sentry.setUser(user)
  }
}

export { sentryInit, setSentryUser }
