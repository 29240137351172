import React from 'react'
import { Space, Tooltip, Tag } from 'antd'
import { Button, ButtonActionBar, CustomBottomAction } from 'modules/core/components'
import { useTranslation } from 'react-i18next'
import { CalendarOutlined } from '@ant-design/icons'
import { dateMMYYYY } from 'modules/core/utils'
import { useFeatureIsOn } from '@growthbook/growthbook-react'

const UserActions = ({ disabled, onClick, titleTooltip = '', date, onClickTools }) => {
  const enabled = useFeatureIsOn('feature-moneda-seguimiento')
  const { t } = useTranslation()

  return (
    <ButtonActionBar position={date ? 'space-between' : 'end'}>
      <Space direction="horizontal">
        {date && (
          <Tag icon={<CalendarOutlined />}>
            {t('CONTROL_LAST_DATE_STATUS_TOOLTIP_TITLE', { date: dateMMYYYY(date, 'YYYY-MM') })}
          </Tag>
        )}
      </Space>
      <Space>
        <Button.Primary
          onClick={onClick}
          disabled={disabled}
          title="CONTROL_MODAL_ACTION_DATA_TITLE"
          extraTitle={{ action: t('ACTION_PUBLISH') }}
          tooltipTitle={t('CONTROL_PUBLISH_REAL_DATA_BUTTON_INFORMATIVE_TOOLTIP_TITLE')}
        />
        {enabled && <Button.Default title="LABEL_TOOLS" onClick={onClickTools} />}
        <CustomBottomAction extraButtons={{ module_name: 'seguimiento' }} />
      </Space>
    </ButtonActionBar>
  )
}
export default UserActions
