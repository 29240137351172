import _ from 'lodash'
import React from 'react'
import { CellStyle } from '../../../../../modules/core/components'
import {
  flatKey,
  formatter,
  normalizeName,
  PivotData,
  redColorScaleGenerator,
  usFmt,
  usFmtPct,
} from '../../../Utilities'

function makeRenderer(opts = {}) {
  class TableRenderer extends React.PureComponent {
    constructor(props) {
      super(props)

      // We need state to record which entries are collapsed and which aren't.
      // This is an object with flat-keys indicating if the corresponding rows
      // should be collapsed.
      this.state = { collapsedRows: {}, collapsedCols: {} }
    }

    getBasePivotSettings() {
      const props = this.props
      const colAttrs = props.cols
      const rowAttrs = props.rows

      const tableOptions = Object.assign(
        {
          rowTotals: true,
          colTotals: true,
        },
        props.tableOptions,
      )

      const rowTotals = tableOptions.rowTotals || colAttrs.length === 0
      const colTotals = tableOptions.colTotals || rowAttrs.length === 0

      const subtotalOptions = Object.assign(
        {
          arrowCollapsed: '⨁',
          arrowExpanded: 'ⴱ',
        },
        props.subtotalOptions,
      )

      const colSubtotalDisplay = Object.assign(
        {
          displayOnTop: false,
          enabled: rowTotals,
          hideOnExpand: false,
        },
        subtotalOptions.colSubtotalDisplay,
      )

      const rowSubtotalDisplay = Object.assign(
        {
          displayOnTop: true,
          enabled: colTotals,
          hideOnExpand: false,
        },
        subtotalOptions.rowSubtotalDisplay,
      )

      const pivotData = new PivotData(
        props,
        !opts.subtotals
          ? {}
          : {
              rowEnabled: rowSubtotalDisplay.enabled,
              colEnabled: colSubtotalDisplay.enabled,
              rowPartialOnTop: rowSubtotalDisplay.displayOnTop,
              colPartialOnTop: colSubtotalDisplay.displayOnTop,
            },
      )

      const rowKeys = pivotData.getRowKeys()
      const colKeys = pivotData.getColKeys()
      // Also pre-calculate all the callbacks for cells, etc... This is nice to have to
      // avoid re-calculations of the call-backs on cell expansions, etc...
      const cellCallbacks = {}
      const rowTotalCallbacks = {}
      const colTotalCallbacks = {}
      let grandTotalCallback = null
      // Custom lines
      const linesOrder = !_.isEmpty(tableOptions?.linesOrder) ? tableOptions.linesOrder : []
      const linesTotal = !_.isEmpty(tableOptions?.linesTotal) ? tableOptions.linesTotal : []
      const linesRatio = !_.isEmpty(tableOptions?.linesRatio) ? tableOptions.linesRatio : []
      const linesRatioN = !_.isEmpty(tableOptions?.linesRatioN) ? tableOptions.linesRatioN : []
      const linesFinancial = !_.isEmpty(tableOptions?.linesFinancial)
        ? tableOptions.linesFinancial
        : null
      const lineVertical = !_.isEmpty(tableOptions?.lineVertical) ? tableOptions.lineVertical : null

      return Object.assign(
        {
          pivotData,
          colAttrs,
          rowAttrs,
          colKeys,
          rowKeys,
          rowTotals,
          colTotals,
          arrowCollapsed: subtotalOptions.arrowCollapsed,
          arrowExpanded: subtotalOptions.arrowExpanded,
          colSubtotalDisplay,
          rowSubtotalDisplay,
          cellCallbacks,
          rowTotalCallbacks,
          colTotalCallbacks,
          grandTotalCallback,
          linesOrder,
          linesTotal,
          linesRatio,
          linesRatioN,
          linesFinancial,
          lineVertical,
        },
        TableRenderer.heatmapMappers(
          pivotData,
          props.tableColorScaleGenerator,
          colTotals,
          rowTotals,
        ),
      )
    }

    clickHandler(pivotData, rowValues, colValues) {
      const colAttrs = this.props.cols
      const rowAttrs = this.props.rows
      const value = pivotData.getAggregator(rowValues, colValues).value()
      const filters = {}
      const colLimit = Math.min(colAttrs.length, colValues.length)
      for (let i = 0; i < colLimit; i++) {
        const attr = colAttrs[i]
        if (colValues[i] !== null) {
          filters[attr] = colValues[i]
        }
      }
      const rowLimit = Math.min(rowAttrs.length, rowValues.length)
      for (let i = 0; i < rowLimit; i++) {
        const attr = rowAttrs[i]
        if (rowValues[i] !== null) {
          filters[attr] = rowValues[i]
        }
      }
      return (e) => this.props.tableOptions.clickCallback(e, value, filters, pivotData)
    }

    collapseAttr(rowOrCol, attrIdx, allKeys) {
      return () => {
        // Collapse an entire attribute.

        const keyLen = attrIdx + 1
        const collapsed = allKeys.filter((k) => k.length === keyLen).map(flatKey)

        const updates = {}
        collapsed.forEach((k) => {
          updates[k] = true
        })

        if (rowOrCol) {
          this.setState((state) => ({
            collapsedRows: Object.assign({}, state.collapsedRows, updates),
          }))
        } else {
          this.setState((state) => ({
            collapsedCols: Object.assign({}, state.collapsedCols, updates),
          }))
        }
      }
    }

    expandAttr(rowOrCol, attrIdx, allKeys) {
      return () => {
        // Expand an entire attribute. This implicitly implies expanding all of the
        // parents as well. It's a bit inefficient but ah well...

        const updates = {}
        allKeys.forEach((k) => {
          for (let i = 0; i <= attrIdx; i++) {
            updates[flatKey(k.slice(0, i + 1))] = false
          }
        })

        if (rowOrCol) {
          this.setState((state) => ({
            collapsedRows: Object.assign({}, state.collapsedRows, updates),
          }))
        } else {
          this.setState((state) => ({
            collapsedCols: Object.assign({}, state.collapsedCols, updates),
          }))
        }
      }
    }

    toggleRowKey(flatRowKey) {
      return () => {
        this.setState((state) => ({
          collapsedRows: Object.assign({}, state.collapsedRows, {
            [flatRowKey]: !state.collapsedRows[flatRowKey],
          }),
        }))
      }
    }

    toggleColKey(flatColKey) {
      return () => {
        this.setState((state) => ({
          collapsedCols: Object.assign({}, state.collapsedCols, {
            [flatColKey]: !state.collapsedCols[flatColKey],
          }),
        }))
      }
    }

    calcAttrSpans(attrArr, numAttrs) {
      // Given an array of attribute values (i.e. each element is another array with
      // the value at every level), compute the spans for every attribute value at
      // every level. The return value is a nested array of the same shape. It has
      // -1's for repeated values and the span number otherwise.

      const spans = []
      // Index of the last new value
      const li = Array(numAttrs).map(() => 0)
      let lv = Array(numAttrs).map(() => null)
      for (let i = 0; i < attrArr.length; i++) {
        // Keep increasing span values as long as the last keys are the same. For
        // the rest, record spans of 1. Update the indices too.
        const cv = attrArr[i]
        const ent = []
        let depth = 0
        const limit = Math.min(lv.length, cv.length)
        while (depth < limit && lv[depth] === cv[depth]) {
          ent.push(-1)
          spans[li[depth]][depth]++
          depth++
        }
        while (depth < cv.length) {
          li[depth] = i
          ent.push(1)
          depth++
        }
        spans.push(ent)
        lv = cv
      }
      return spans
    }

    static heatmapMappers(pivotData, colorScaleGenerator, colTotals, rowTotals) {
      let valueCellColors = () => {}
      let rowTotalColors = () => {}
      let colTotalColors = () => {}
      if (opts.heatmapMode) {
        if (colTotals) {
          const colTotalValues = Object.values(pivotData.colTotals).map((a) => a.value())
          colTotalColors = colorScaleGenerator(colTotalValues)
        }
        if (rowTotals) {
          const rowTotalValues = Object.values(pivotData.rowTotals).map((a) => a.value())
          rowTotalColors = colorScaleGenerator(rowTotalValues)
        }
        if (opts.heatmapMode === 'full') {
          const allValues = []
          Object.values(pivotData.tree).map((cd) =>
            Object.values(cd).map((a) => allValues.push(a.value())),
          )
          const colorScale = colorScaleGenerator(allValues)
          valueCellColors = (r, c, v) => colorScale(v)
        } else if (opts.heatmapMode === 'row') {
          const rowColorScales = {}
          Object.entries(pivotData.tree).map(([rk, cd]) => {
            const rowValues = Object.values(cd).map((a) => a.value())
            rowColorScales[rk] = colorScaleGenerator(rowValues)
          })
          valueCellColors = (r, c, v) => rowColorScales[flatKey(r)](v)
        } else if (opts.heatmapMode === 'col') {
          const colColorScales = {}
          const colValues = {}
          Object.values(pivotData.tree).map((cd) =>
            Object.entries(cd).map(([ck, a]) => {
              if (!(ck in colValues)) {
                colValues[ck] = []
              }
              colValues[ck].push(a.value())
            }),
          )
          for (const k in colValues) {
            colColorScales[k] = colorScaleGenerator(colValues[k])
          }
          valueCellColors = (r, c, v) => colColorScales[flatKey(c)](v)
        }
      }
      return { valueCellColors, rowTotalColors, colTotalColors }
    }

    renderColHeaderRow(attrName, attrIdx, pivotSettings, lineVertical) {
      const {
        rowAttrs,
        colAttrs,
        colKeys,
        visibleColKeys,
        colAttrSpans,
        rowTotals,
        arrowExpanded,
        arrowCollapsed,
        colSubtotalDisplay,
        maxColVisible,
      } = pivotSettings

      const needToggle =
        opts.subtotals && colSubtotalDisplay.enabled && attrIdx !== colAttrs.length - 1
      let clickHandle = null
      let subArrow = null
      if (needToggle) {
        clickHandle =
          attrIdx + 1 < maxColVisible
            ? this.collapseAttr(false, attrIdx, colKeys)
            : this.expandAttr(false, attrIdx, colKeys)
        subArrow = (attrIdx + 1 < maxColVisible ? arrowExpanded : arrowCollapsed) + ' '
      }
      const attrNameCell = (
        <th
          key="label"
          className="pvtAxisLabel width-min-250"
          onClick={clickHandle}
          style={{ textAlign: 'right' }}
        >
          {subArrow}
          {attrName}
        </th>
      )

      const attrValueCells = []
      const rowIncrSpan = rowAttrs.length !== 0 ? 1 : 0
      // Iterate through columns. Jump over duplicate values.
      let i = 0
      while (i < visibleColKeys.length) {
        const colKey = visibleColKeys[i]
        const colSpan = attrIdx < colKey.length ? colAttrSpans[i][attrIdx] : 1
        if (attrIdx < colKey.length) {
          const rowSpan = 1 + (attrIdx === colAttrs.length - 1 ? rowIncrSpan : 0)
          const flatColKey = flatKey(colKey.slice(0, attrIdx + 1))
          const onClick = needToggle ? this.toggleColKey(flatColKey) : null

          const hasAV = attrIdx + 1 === colKey.length
          let currentColSpan = colSpan
          if (lineVertical && !hasAV) {
            currentColSpan = colSpan * 2
          } else {
            currentColSpan = colSpan
          }

          attrValueCells.push(
            <th
              className="pvtColLabel"
              key={'colKey-' + flatColKey}
              colSpan={currentColSpan}
              rowSpan={rowSpan}
              onClick={onClick}
            >
              {needToggle
                ? (this.state.collapsedCols[flatColKey] ? arrowCollapsed : arrowExpanded) + ' '
                : null}
              {colKey[attrIdx]}
            </th>,
          )
          if (lineVertical && hasAV) {
            attrValueCells.push(
              <th
                className="pvtColLabel"
                key={'colKey-AV' + flatColKey}
                colSpan={colSpan}
                rowSpan={rowSpan}
              >
                AV
              </th>,
            )
          }
        } else if (attrIdx === colKey.length) {
          const rowSpan = colAttrs.length - colKey.length + rowIncrSpan
          attrValueCells.push(
            <th
              className="pvtColLabel"
              key={'colKeyBuffer-' + flatKey(colKey)}
              colSpan={colSpan}
              rowSpan={rowSpan}
            >
              Subtotal
            </th>,
          )

          if (lineVertical) {
            attrValueCells.push(
              <th
                className="pvtColLabel"
                key={'colKeyBuffer-av' + flatKey(colKey)}
                colSpan={colSpan}
                rowSpan={rowSpan}
              >
                AV
              </th>,
            )
          }
        }
        // The next colSpan columns will have the same value anyway...
        i = i + colSpan
      }

      const totalCell = []
      if (attrIdx === 0 && rowTotals) {
        totalCell.push(
          <th
            key="total"
            className="pvtTotalLabel"
            rowSpan={colAttrs.length + Math.min(rowAttrs.length, 1)}
          >
            Total
          </th>,
        )

        if (lineVertical) {
          totalCell.push(
            <th
              key="total-av"
              className="pvtTotalLabel"
              rowSpan={colAttrs.length + Math.min(rowAttrs.length, 1)}
            >
              AV
            </th>,
          )
        }
      }

      const cells = [attrNameCell, ...attrValueCells, ...totalCell]
      return <tr key={`colAttr-${attrIdx}`}>{cells}</tr>
    }

    renderRowHeaderRow(pivotSettings) {
      // Render just the attribute names of the rows (the actual attribute values
      // will show up in the individual rows).

      const {
        rowAttrs,
        rowKeys,
        arrowCollapsed,
        arrowExpanded,
        rowSubtotalDisplay,
        maxRowVisible,
      } = pivotSettings

      const rowLabel = rowAttrs.map((it) => it).join(' / ')
      const i = 0
      const needLabelToggle =
        opts.subtotals && rowSubtotalDisplay.enabled && i !== rowAttrs.length - 1
      let clickHandle = null
      let subArrow = null
      if (needLabelToggle) {
        clickHandle =
          i + 1 < maxRowVisible
            ? this.collapseAttr(true, i, rowKeys)
            : this.expandAttr(true, i, rowKeys)
        subArrow = (i + 1 < maxRowVisible ? arrowExpanded : arrowCollapsed) + ' '
      }

      return (
        <tr key="rowHdr">
          <th className="pvtAxisLabel width-min-250" key={`rowAttr-${i}`} onClick={clickHandle}>
            {subArrow} {rowLabel}
          </th>
        </tr>
      )
    }

    renderTableRow(rowKey, rowIdx, pivotSettings, line) {
      // Render a single row in the pivot table.

      const {
        rowAttrs,
        rowAttrSpans,
        visibleColKeys,
        pivotData,
        rowTotals,
        valueCellColors,
        rowTotalColors,
        arrowExpanded,
        arrowCollapsed,
        lineVertical,
      } = pivotSettings

      const flatRowKey = flatKey(rowKey)
      const attrValueCells = rowKey.map((r, i) => {
        const rowSpan = rowAttrSpans[rowIdx][i]
        if (rowSpan > 0) {
          const flatRowKey = flatKey(rowKey.slice(0, i + 1))
          const needRowToggle = opts.subtotals && i !== rowAttrs.length - 1
          const onClick = needRowToggle ? this.toggleRowKey(flatRowKey) : null

          return (
            <th
              key={`rowKeyLabel-${i}`}
              className={`pvtRowLabel pl-${i} width-min-250`}
              style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
              rowSpan={1}
              onClick={onClick}
            >
              {needRowToggle
                ? (this.state.collapsedRows[flatRowKey] ? arrowCollapsed : arrowExpanded) + ' '
                : null}
              {r}
            </th>
          )
        }
        return null
      })

      let valueCells = []
      visibleColKeys.forEach((colKey) => {
        const flatColKey = flatKey(colKey)
        const agg = pivotData.getAggregator(rowKey, colKey)
        const aggValue = agg.value()
        const style = valueCellColors(rowKey, colKey, aggValue)

        valueCells.push(
          <td
            className="pvtVal"
            key={'pvtVal-' + flatColKey}
            style={{ ...(style ? style : line?.color ? { backgroundColor: line?.color } : {}) }}
          >
            {agg.format(aggValue)}
          </td>,
        )

        if (lineVertical) {
          const valueAV = this.calculateAnalysisVertical(rowKey, colKey, pivotSettings, aggValue)
          valueCells.push(
            <td
              className="pvtVal"
              key={'pvtVal-AV' + flatColKey}
              style={{ ...(style ? style : line?.color ? { backgroundColor: line?.color } : {}) }}
            >
              <CellStyle.VariationTable value={valueAV} amount={valueAV} />
            </td>,
          )
        }
      })

      let totalCell = []
      if (rowTotals) {
        const agg = pivotData.getAggregator(rowKey, [])
        const aggValue = agg.value()
        const style = rowTotalColors(aggValue)
        totalCell.push(
          <td
            key="total"
            className="pvtTotal"
            style={{ ...(style ? style : line?.color ? { backgroundColor: line?.color } : {}) }}
          >
            {agg.format(aggValue)}
          </td>,
        )

        if (lineVertical) {
          const valueAV = this.calculateAnalysisVertical(rowKey, [], pivotSettings, aggValue)
          totalCell.push(
            <td
              key="total-av"
              className="pvtTotal"
              style={{ ...(style ? style : line?.color ? { backgroundColor: line?.color } : {}) }}
            >
              <CellStyle.VariationTable value={valueAV} amount={valueAV} />
            </td>,
          )
        }
      }

      const rowCells = [...attrValueCells, ...valueCells, ...totalCell]

      return <tr key={'keyRow-' + flatRowKey}>{rowCells}</tr>
    }

    getFormatter(pivotData) {
      let formatterFunction = usFmt
      if (formatter.hasOwnProperty(pivotData.props.aggregatorName)) {
        formatterFunction = formatter[pivotData.props.aggregatorName]
      }
      return formatterFunction
    }

    getAggregator(pivotData, rowKey, colKey) {
      try {
        const agg = pivotData.getAggregator(rowKey, colKey)
        return agg.value()
      } catch (e) {
        return 0
      }
    }

    renderTableTotalRow(rowKey, rowIdx, pivotSettings, lineTotal, line) {
      const { visibleColKeys, pivotData, rowTotals, lineVertical } = pivotSettings

      const flatRowKey = flatKey(rowKey)
      const formatterFunction = this.getFormatter(pivotData)

      const lines = lineTotal.lines.reduce((mergedLines, it) => {
        return mergedLines.concat(it.lines)
      }, [])

      const attrValueCells = rowKey.map((r, i) => {
        return (
          <th
            rowSpan={1}
            key={`rowKeyLabel-${i}`}
            className={`pvtRowLabel width-min-250 ${!line?.color ? 'pvtTotalizer' : ''}  pl-${i}`}
            style={{
              ...(line?.color
                ? { backgroundColor: line?.color }
                : { backgroundColor: '#eaeaea !important' }),
            }}
          >
            {r}
          </th>
        )
      })

      const valueCells = []
      visibleColKeys.forEach((colKey) => {
        const flatColKey = flatKey(colKey)
        const accumulator = lines.reduce((acc, it) => {
          const amount = this.getAggregator(pivotData, [normalizeName(it)], colKey)
          return acc + amount
        }, 0)

        valueCells.push(
          <td
            key={'pvtVal-' + flatColKey}
            className={`pvtVal ${!line?.color ? 'pvtTotalizer' : ''}`}
            style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
          >
            {formatterFunction(accumulator)}
          </td>,
        )

        if (lineVertical) {
          const valueAV = this.calculateAnalysisVertical(rowKey, colKey, pivotSettings, accumulator)
          valueCells.push(
            <td
              key={'pvtVal-AV' + flatColKey}
              className={`pvtVal ${!line?.color ? 'pvtTotalizer' : ''}`}
              style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
            >
              <CellStyle.VariationTable value={valueAV} amount={valueAV} />
            </td>,
          )
        }
      })

      let totalCell = []
      if (rowTotals) {
        const accumulator = lines.reduce((acc, it) => {
          const amount = this.getAggregator(pivotData, [normalizeName(it)], [])
          return acc + amount
        }, 0)

        totalCell.push(
          <td
            key="total"
            className={`pvtTotal ${!line?.color ? 'pvtTotalizer' : ''}`}
            style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
          >
            {formatterFunction(accumulator)}
          </td>,
        )

        if (lineVertical) {
          const valueAV = this.calculateAnalysisVertical(rowKey, [], pivotSettings, accumulator)
          totalCell.push(
            <td
              key="total-av"
              className={`pvtTotal ${!line?.color ? 'pvtTotalizer' : ''}`}
              style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
            >
              <CellStyle.VariationTable value={valueAV} amount={valueAV} />
            </td>,
          )
        }
      }

      const rowCells = [...attrValueCells, ...valueCells, ...totalCell]

      return <tr key={'keyRow-' + flatRowKey}>{rowCells}</tr>
    }

    renderTableLineZeroRow(rowKey, rowIdx, pivotSettings, line) {
      const { visibleColKeys, pivotData, rowTotals, lineVertical } = pivotSettings

      const flatRowKey = flatKey(rowKey)
      const formatterFunction = this.getFormatter(pivotData)

      const attrValueCells = rowKey.map((r, i) => {
        return (
          <th
            rowSpan={1}
            key={`rowKeyLabel-${i}`}
            className={`pvtRowLabel width-min-250 pl-${i}`}
            style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
          >
            {r}
          </th>
        )
      })

      const valueCells = []
      visibleColKeys.forEach((colKey) => {
        const flatColKey = flatKey(colKey)
        const accumulator = 0

        valueCells.push(
          <td
            className="pvtVal"
            key={'pvtVal-' + flatColKey}
            style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
          >
            {formatterFunction(accumulator)}
          </td>,
        )

        if (lineVertical) {
          const valueAV = this.calculateAnalysisVertical(rowKey, colKey, pivotSettings, accumulator)
          valueCells.push(
            <td
              className="pvtVal"
              key={'pvtVal-AV' + flatColKey}
              style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
            >
              <CellStyle.VariationTable value={valueAV} amount={valueAV} />
            </td>,
          )
        }
      })

      let totalCell = []
      if (rowTotals) {
        const accumulator = 0

        totalCell.push(
          <td
            key="total"
            className="pvtTotal"
            style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
          >
            {formatterFunction(accumulator)}
          </td>,
        )

        if (lineVertical) {
          const valueAV = this.calculateAnalysisVertical(rowKey, [], pivotSettings, accumulator)
          totalCell.push(
            <td
              key="total-av"
              className="pvtTotal"
              style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
            >
              <CellStyle.VariationTable value={valueAV} amount={valueAV} />
            </td>,
          )
        }
      }

      const rowCells = [...attrValueCells, ...valueCells, ...totalCell]

      return <tr key={'keyRow-' + flatRowKey}>{rowCells}</tr>
    }

    renderTableRatioRow(rowKey, rowIdx, pivotSettings, lineRatio, formatter = usFmt, line) {
      const { visibleColKeys, pivotData, rowTotals, lineVertical } = pivotSettings
      const flatRowKey = flatKey(rowKey)
      let lines1 = []
      let lines2 = []
      if (!_.isEmpty(lineRatio.lines)) {
        lines1 = lineRatio.lines[0]
        lines2 = lineRatio.lines[1]
      }

      const attrValueCells = rowKey.map((r, i) => {
        return (
          <th
            rowSpan={1}
            key={`rowKeyLabel-${i}`}
            className={`pvtRowLabel width-min-250 ${!line?.color ? 'pvtRatio' : ''} pl-${i}`}
            style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
          >
            {r}
          </th>
        )
      })

      const valueCells = []
      visibleColKeys.forEach((colKey) => {
        const flatColKey = flatKey(colKey)
        let result = 0
        const accumulator1 = lines1.lines.reduce((acc, it) => {
          const amount = this.getAggregator(pivotData, [normalizeName(it)], colKey)
          return acc + amount
        }, 0)
        const accumulator2 = lines2.lines.reduce((acc, it) => {
          const amount = this.getAggregator(pivotData, [normalizeName(it)], colKey)
          return acc + amount
        }, 0)

        if (accumulator2 !== 0.0) {
          result = accumulator1 / accumulator2
        }

        valueCells.push(
          <td
            className={`pvtVal ${!line?.color ? 'pvtRatio' : ''}`}
            key={'pvtVal-' + flatColKey}
            style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
          >
            {formatter(result)}
          </td>,
        )

        if (lineVertical) {
          const valueAV = this.calculateAnalysisVertical(rowKey, colKey, pivotSettings, result)
          valueCells.push(
            <td
              className="pvtVal"
              key={'pvtVal-AV' + flatColKey}
              style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
            >
              <CellStyle.VariationTable value={valueAV} amount={valueAV} />
            </td>,
          )
        }
      })

      let totalCell = []
      if (rowTotals) {
        let result = 0
        const accumulator1 = lines1.lines.reduce((acc, it) => {
          const amount = this.getAggregator(pivotData, [normalizeName(it)], [])
          return acc + amount
        }, 0)

        const accumulator2 = lines2.lines.reduce((acc, it) => {
          const amount = this.getAggregator(pivotData, [normalizeName(it)], [])
          return acc + amount
        }, 0)

        if (accumulator2 !== 0.0) {
          result = accumulator1 / accumulator2
        }

        totalCell.push(
          <td
            key="total"
            className={`pvtTotal ${!line?.color ? 'pvtRatio' : ''}`}
            style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
          >
            {formatter(result)}
          </td>,
        )

        if (lineVertical) {
          const valueAV = this.calculateAnalysisVertical(rowKey, [], pivotSettings, result)
          totalCell.push(
            <td
              key="total-av"
              className={`pvtTotal ${!line?.color ? 'pvtRatio' : ''}`}
              style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
            >
              <CellStyle.VariationTable value={valueAV} amount={valueAV} />
            </td>,
          )
        }
      }

      const rowCells = [...attrValueCells, ...valueCells, ...totalCell]

      return <tr key={'keyRow-' + flatRowKey}>{rowCells}</tr>
    }

    editStartFinancial(pivotData, flatColKey, amount) {
      const filters = { period_date: flatColKey }
      return (e) => this.props.tableOptions.clickCallback(e, amount, filters, pivotData)
    }

    renderStartFinancial(pivotSettings, lineTotal) {
      const { visibleColKeys, pivotData, rowTotals, linesFinancial } = pivotSettings
      const flatRowKey = flatKey(['Saldo Inicial'])
      const formatterFunction = this.getFormatter(pivotData)

      const lines = lineTotal.lines.reduce((mergedLines, it) => {
        return mergedLines.concat(it.lines)
      }, [])

      const financialLabelCell = (
        <th key="label" className="pvtRowLabel width-min-250 pvtFinancial">
          SALDO INICIAL
        </th>
      )

      let valueCells = []
      let count = 0
      let lastAmount = 0
      let lastAccumulator = 0
      visibleColKeys.map((colKey) => {
        const flatColKey = flatKey(colKey)
        let amount = 0
        const accumulator = lines.reduce((acc, it) => {
          const amount = this.getAggregator(pivotData, [normalizeName(it)], colKey)
          return acc + amount
        }, 0)

        if (count === 0) {
          amount = linesFinancial[flatColKey]?.initial_amount || 0
          lastAmount = amount
          lastAccumulator = accumulator
        } else {
          amount = lastAccumulator + lastAmount
          lastAmount = amount
          lastAccumulator = accumulator
        }
        // Edit financial value
        let handleClick = () => {}
        if (count === 0) {
          handleClick = this.editStartFinancial(pivotData, flatColKey, amount)
        }

        count += 1

        valueCells.push(
          <td className="pvtVal pvtFinancial" key={'pvtVal-' + flatColKey} onClick={handleClick}>
            {formatterFunction(amount)}
          </td>,
        )
      })

      let totalCell = null
      if (rowTotals) {
        totalCell = (
          <td key="total" className="pvtTotal pvtFinancial">
            -
          </td>
        )
      }

      const rowCells = [financialLabelCell, ...valueCells, totalCell]

      return <tr key={'keyRow-' + flatRowKey}>{rowCells}</tr>
    }

    renderEndFinancial(pivotSettings, lineTotal) {
      const { visibleColKeys, pivotData, rowTotals, linesFinancial } = pivotSettings
      const flatRowKey = flatKey(['Saldo Final'])
      const formatterFunction = this.getFormatter(pivotData)

      const lines = lineTotal.lines.reduce((mergedLines, it) => {
        return mergedLines.concat(it.lines)
      }, [])

      const financialLabelCell = (
        <th key="label" className="pvtRowLabel width-min-250 pvtFinancial">
          SALDO FINAL
        </th>
      )

      let valueCells = []
      let count = 0
      let lastAmount = 0
      visibleColKeys.map((colKey) => {
        const flatColKey = flatKey(colKey)
        let amount = 0
        const accumulator = lines.reduce((acc, it) => {
          const amount = this.getAggregator(pivotData, [normalizeName(it)], colKey)
          return acc + amount
        }, 0)

        if (count === 0) {
          amount = accumulator + (linesFinancial[flatColKey]?.initial_amount || 0)
          lastAmount = amount
        } else {
          amount = accumulator + lastAmount
          lastAmount = amount
        }

        count += 1

        valueCells.push(
          <td className="pvtVal pvtFinancial" key={'pvtVal-' + flatColKey}>
            {formatterFunction(amount)}
          </td>,
        )
      })

      let totalCell = null
      if (rowTotals) {
        totalCell = (
          <td key="total" className="pvtTotal pvtFinancial">
            -
          </td>
        )
      }

      const rowCells = [financialLabelCell, ...valueCells, totalCell]

      return <tr key={'keyRow-' + flatRowKey}>{rowCells}</tr>
    }

    renderLinesRow(rowKey, rowIdx, pivotSettings, visibleRowKeys) {
      const { linesOrder, linesTotal, linesRatio, linesRatioN } = pivotSettings
      let isTree = false
      let flatRowKey = flatKey(rowKey)

      if (rowKey.length > 1) {
        flatRowKey = rowKey[0]
        isTree = true
      }

      const line = linesOrder?.find((it) => normalizeName(it.name) === flatRowKey)
      const lineTotal = linesTotal?.find((it) => normalizeName(it.name) === flatRowKey)
      const lineRatio = linesRatio?.find((it) => normalizeName(it.name) === flatRowKey)
      const lineRatioN = linesRatioN?.find((it) => normalizeName(it.name) === flatRowKey)
      const originalRowIdx = visibleRowKeys.findIndex((entry) => flatKey(entry) === flatKey(rowKey))

      if (lineTotal) {
        if (!isTree) {
          return this.renderTableTotalRow(rowKey, rowIdx, pivotSettings, lineTotal, line)
        }
      } else if (lineRatio) {
        if (!isTree) {
          return this.renderTableRatioRow(rowKey, rowIdx, pivotSettings, lineRatio, usFmtPct, line)
        }
      } else if (lineRatioN) {
        if (!isTree) {
          return this.renderTableRatioRow(rowKey, rowIdx, pivotSettings, lineRatioN, usFmt, line)
        }
      } else if (originalRowIdx < 0) {
        return this.renderTableLineZeroRow(rowKey, rowIdx, pivotSettings, line)
      } else {
        return this.renderTableRow(rowKey, originalRowIdx, pivotSettings, line)
      }
    }

    calculateAnalysisVertical(rowKey, colKey, pivotSettings, valueLine) {
      const { linesOrder, linesTotal, lineVertical, pivotData } = pivotSettings

      const line = linesOrder?.find((it) => normalizeName(it.name) === lineVertical)
      const lineTotal = linesTotal?.find((it) => normalizeName(it.name) === lineVertical)
      let aggValue = 0

      if (lineTotal) {
        const lines = lineTotal.lines.reduce((mergedLines, it) => {
          return mergedLines.concat(it.lines)
        }, [])

        aggValue = lines.reduce((acc, it) => {
          const amount = this.getAggregator(pivotData, [normalizeName(it)], colKey)
          return acc + amount
        }, 0)
      } else if (line) {
        const agg = this.getAggregator(pivotData, [line.name], colKey)
        aggValue = agg ?? 0
      }

      const amount = ((valueLine ?? 0) * 100) / aggValue / 100

      return !this.isNumber(amount) ? 0 : amount
    }

    isNumber(num) {
      return !isNaN(num) && num !== Infinity && num !== -Infinity
    }

    visibleKeys(keys, collapsed, numAttrs, subtotalDisplay) {
      return keys.filter(
        (key) =>
          // Is the key hidden by one of its parents?
          !key.some((k, j) => collapsed[flatKey(key.slice(0, j))]) &&
          // Leaf key.
          (key.length === numAttrs ||
            // Children hidden. Must show total.
            flatKey(key) in collapsed ||
            // Don't hide totals.
            !subtotalDisplay.hideOnExpand),
      )
    }

    render() {
      if (this.cachedProps !== this.props) {
        this.cachedProps = this.props
        this.cachedBasePivotSettings = this.getBasePivotSettings()
      }

      const {
        colAttrs,
        rowAttrs,
        rowKeys,
        colKeys,
        rowSubtotalDisplay,
        colSubtotalDisplay,
        linesOrder,
        lineVertical,
        linesFinancial,
        linesTotal,
      } = this.cachedBasePivotSettings

      // Need to account for exclusions to compute the effective row
      // and column keys.
      const visibleRowKeys = opts.subtotals
        ? this.visibleKeys(rowKeys, this.state.collapsedRows, rowAttrs.length, rowSubtotalDisplay)
        : rowKeys
      const visibleColKeys = opts.subtotals
        ? this.visibleKeys(colKeys, this.state.collapsedCols, colAttrs.length, colSubtotalDisplay)
        : colKeys
      const pivotSettings = Object.assign(
        {
          visibleRowKeys,
          maxRowVisible: Math.max(...visibleRowKeys.map((k) => k.length)),
          visibleColKeys,
          maxColVisible: Math.max(...visibleColKeys.map((k) => k.length)),
          rowAttrSpans: this.calcAttrSpans(visibleRowKeys, rowAttrs.length),
          colAttrSpans: this.calcAttrSpans(visibleColKeys, colAttrs.length),
        },
        this.cachedBasePivotSettings,
      )

      let customVisibleRowKeys = []
      linesOrder.forEach((it) => {
        if (it.type === 'grouper') {
          const listFilter = visibleRowKeys.filter((entry) => it.name === entry[0])
          if (listFilter.length > 0) {
            customVisibleRowKeys.push(...listFilter)
          } else {
            customVisibleRowKeys.push([it.name])
          }
        } else {
          customVisibleRowKeys.push([it.name])
        }
      })

      let lastLineTotal = {}
      if (!_.isEmpty(linesOrder)) {
        const linesTotalFilter = linesOrder.filter((it) => it.type === 'totalizer')
        if (!_.isEmpty(linesTotalFilter)) {
          const findLastTotal = _.orderBy(linesTotalFilter, 'order', 'desc')[0]
          lastLineTotal = linesTotal.find((it) => it.name === findLastTotal.name)
        }
      }

      let enabledFinancial = false
      if (!_.isEmpty(this.props.cols) && this.props.cols.length === 1) {
        enabledFinancial = this.props.cols[0] === 'Fecha'
      }

      return (
        <table className="pvtTable">
          <thead>
            {colAttrs.map((it, index) =>
              this.renderColHeaderRow(it, index, pivotSettings, lineVertical),
            )}
            {rowAttrs.length !== 0 && this.renderRowHeaderRow(pivotSettings)}
          </thead>
          <tbody>
            {enabledFinancial &&
              lineVertical &&
              linesFinancial &&
              this.renderStartFinancial(pivotSettings, lastLineTotal)}
            {customVisibleRowKeys.map((r, i) =>
              this.renderLinesRow(r, i, pivotSettings, visibleRowKeys),
            )}
            {enabledFinancial &&
              lineVertical &&
              linesFinancial &&
              this.renderEndFinancial(pivotSettings, lastLineTotal)}
          </tbody>
        </table>
      )
    }
  }

  TableRenderer.defaultProps = PivotData.defaultProps
  TableRenderer.propTypes = PivotData.propTypes
  TableRenderer.defaultProps.tableColorScaleGenerator = redColorScaleGenerator
  TableRenderer.defaultProps.tableOptions = {}
  return TableRenderer
}

export default makeRenderer
