import { updateCommentInReducer } from 'modules/core/utils'
import Immutable from 'seamless-immutable'
import { ROWS } from '../core/constants'
import * as types from './constants'

const initialDataPhaseBudget = [
  {
    key: 'base',
    title: 'Base',
    disabled: false,
    buttonName: 'PLANNING_PHASES_SEND_TOPDOWN_ACTION',
    tooltipText: 'PLANNING_PHASES_BASE_INFORMATIVE_TOOLTIPE_TITLE',
  },
  {
    key: 'top_down',
    title: 'TopDown',
    disabled: true,
    buttonName: 'PLANNING_PHASES_SEND_BOTTOMUP_ACTION',
    tooltipText: 'PLANNING_PHASES_TOPDOWN_INFORMATIVE_TOOLTIPE_TITLE',
  },
  {
    key: 'bottom_up',
    title: 'BottomUp',
    disabled: true,
    buttonName: 'PLANNING_PHASES_SEND_CLOSING_ACTION',
    tooltipText: 'PLANNING_PHASES_BOTTOMUP_INFORMATIVE_TOOLTIP_TITLE',
  },
  {
    key: 'closing',
    title: 'LABEL_CLOSING',
    disabled: true,
    buttonName: 'ACTION_CLOSE',
    tooltipText: 'PLANNING_PHASES_CIERRE_INFORMATIVE_TOOLTIP_TITLE',
  },
]

const initialState = Immutable({
  periodList: [],
  budgetInstanceList: {
    count: 0,
    values: [],
  },
  dataPhaseBudget: initialDataPhaseBudget,
  budgetInstanceDetail: {},
  dataNestedTablePhase: {
    data: [],
    fields: [],
    meta: [],
    // columns:[],
    // rows:[],
    // num_pages:1
  },
  globalBudgetList: {
    count: 0,
    values: [],
  },
  globalBudgetDetail: {},
  globalBudgetComparisonTable: [],
  globalBudgetEvolutionTable: {
    columns: [],
    rows: [],
  },
  budgetKPIS: [],
  globalBudgetChart: [],
  globalBudgetKpi: [],
  mlList: [],
  dataEvolutionBudgetReport: {
    dataSource: [],
    fields: [],
    meta: [],
    linesOrder: [],
    linesTotal: [],
    linesRatio: [],
    linesRatioN: [],
  },
  dataComparativeBudgetReport: {
    dataSource: [],
    fields: [],
    meta: [],
    linesOrder: [],
    linesTotal: [],
    linesRatio: [],
    linesRatioN: [],
  },
  dataRdnPercentagePlanning: {
    dataSource: [],
    fields: [],
    meta: [],
  },
})

const addNewNode = (nodes, nodeId, newNode) => {
  let newNodes = []
  nodes.forEach((node) => {
    if (node.id === nodeId) {
      node = {
        ...node,
        children: newNode && newNode,
      }
    }
    if (node.id !== nodeId && node.children) {
      node = {
        ...node,
        children: addNewNode(node.children, nodeId, newNode),
      }
    }
    newNodes.push(node)
  })
  return newNodes
}

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = initialState, action) => {
  let data
  switch (action.type) {
    case types.FETCH_PERIODS_SUCCESS:
      data = action.payload.data.periods
      return state.merge({
        periodList: data,
      })

    case types.FETCH_BUDGET_INSTANCE_LIST_SUCCESS:
      data = action.payload.data.results
      return state.merge({
        budgetInstanceList: {
          count: data.length,
          values: data,
        },
      })

    case types.FETCH_BUDGET_INSTANCE_DETAIL_SUCCESS:
      return state.merge({
        budgetInstanceDetail: action.payload.data,
      })

    case types.FETCH_DATA_NESTED_TABLE_BY_PHASE_SUCCESS:
      // const page = action.meta.previousAction.payload.request.params.page
      // let auxrows =  page === 1 ? action.payload.data.rows : [...state.dataNestedTablePhase.rows, ...action.payload.data.rows]
      return state.merge({
        dataNestedTablePhase: {
          data: action.payload.data.data,
          fields: action.payload.data.fields,
          // columns: action.payload.data.colums,
          // rows: auxrows,
          // num_pages: action.payload.data.num_pages
        },
      })

    case types.FETCH_EXPAND_NESTED_TABLE_ROW_SUCCESS:
      const nestedparentId = action.payload.config.params.rowId
      let auxReportTableByPeriods = state.dataNestedTablePhase.rows.asMutable({
        deep: true,
      })
      return state.merge({
        dataNestedTablePhase: {
          ...state.dataNestedTablePhase,
          rows: addNewNode(auxReportTableByPeriods, nestedparentId, action.payload.data.rows),
        },
      })

    case types.FETCH_GLOBAL_BUDGET_LIST_SUCCESS:
      return state.merge({
        globalBudgetList: {
          count: action.payload.data.length,
          values: action.payload.data.map((global) => {
            return { ...global, type: 'budget' }
          }),
        },
      })

    case types.FETCH_GLOBAL_BUDGET_DETAIL_SUCCESS:
      return state.merge({
        globalBudgetDetail: action.payload.data,
      })

    case types.SET_DEFAULT_BUDGET_SUCCESS:
      const globalBudgetId = action.payload.config.params.id
      return state.merge({
        globalBudgetList: {
          ...state.globalBudgetList,
          values: state.globalBudgetList.values.map((budget) => {
            if (budget.id === globalBudgetId) {
              return {
                ...budget,
                predefined: true,
              }
            }
            return budget
          }),
        },
      })

    case types.FETCH_GLOBAL_BUDGET_COMPARISON_TABLE_SUCCESS:
      return state.merge({
        globalBudgetComparisonTable: action.payload.data.map((row) => {
          return { ...row, children: row.type === 'totalizer' ? null : [] }
        }),
      })

    case types.FETCH_COMPARISON_TABLE_OPEN_ROW_SUCCESS:
      const idParent = action.payload.config.params.rowId
      let auxComparisonTableRow = state.globalBudgetComparisonTable.asMutable({
        deep: true,
      })

      return state.merge({
        globalBudgetComparisonTable: addNewNode(
          auxComparisonTableRow,
          idParent,
          action.payload.data,
        ),
      })

    case types.FETCH_GLOBAL_BUDGET_CHART_SUCCESS:
      return state.merge({
        globalBudgetChart: action.payload.data,
      })

    case types.FETCH_GLOBAL_BUDGET_EVOLUTION_TABLE_SUCCESS:
      return state.merge({
        globalBudgetEvolutionTable: {
          ...action.payload.data,
          rows: action.payload.data.rows.map((row) => {
            return { ...row, children: row.type === 'totalizer' ? null : [] }
          }),
        },
      })

    case types.FETCH_GLOBAL_BUDGET_KPI_SUCCESS:
      const dataMutable = action.payload.data
      dataMutable.unshift(dataMutable.pop())
      return state.merge({
        globalBudgetKpi: action.payload.data,
      })

    case types.FETCH_EVOLUTION_TABLE_OPEN_ROW_SUCCESS:
      const parentId = action.payload.config.params.rowId
      let auxEvolutionTableRow = state.globalBudgetEvolutionTable.rows.asMutable({
        deep: true,
      })

      return state.merge({
        globalBudgetEvolutionTable: {
          ...state.globalBudgetEvolutionTable,
          rows: addNewNode(auxEvolutionTableRow, parentId, action.payload.data),
        },
      })

    case types.SYNC_CREATE_BUDGET_COMMENT: {
      const { reducerKey, ...data } = action.payload.data
      return state.merge(
        updateCommentInReducer({
          state,
          reducerKey,
          data,
        }),
      )
    }

    case types.SYNC_DELETE_BUDGET_COMMENT: {
      const { reducerKey, ...data } = action.payload.data
      return state.merge(
        updateCommentInReducer({
          state,
          reducerKey,
          data,
        }),
      )
    }

    case types.SYNC_EDIT_BUDGET_COMMENT: {
      const { reducerKey, ...data } = action.payload.data
      return state.merge(
        updateCommentInReducer({
          state,
          reducerKey,
          data,
        }),
      )
    }

    case types.FETCH_BUDGET_KPIS_SUCCESS:
      return state.merge({
        budgetKPIS: action.payload.data,
      })

    case types.SET_BUDGET_ORDER:
      return state.merge({
        budgetInstanceList: {
          ...state.budgetInstanceList,
          values: action.payload,
        },
      })

    case types.SET_GLOBAL_BUDGET_ORDER:
      return state.merge({
        globalBudgetList: {
          ...state.globalBudgetList,
          values: action.payload,
        },
      })

    case types.FETCH_ML_LIST_SUCCESS:
      return state.merge({
        mlList: action.payload.data,
      })

    case types.FETCH_DATA_EVOLUTION_BY_OVERALL_BUDGET_SUCCESS:
      const lines = action.payload.data.totalizer ?? []
      return state.merge({
        dataEvolutionBudgetReport: {
          dataSource: action.payload.data.data,
          fields: action.payload.data.fields,
          meta: action.payload.data.meta,
          linesOrder: action.payload.data.order_columns,
          linesTotal: lines.filter((it) => it.type === ROWS.TOTALIZER),
          linesRatio: lines.filter((it) => it.type === ROWS.RATIO),
          linesRatioN: lines.filter((it) => it.type === ROWS.RATION),
        },
      })

    case types.FETCH_DATA_COMPARATIVE_BY_OVERALL_BUDGET_SUCCESS:
      const linesComparative = action.payload.data.totalizer ?? []
      return state.merge({
        dataComparativeBudgetReport: {
          dataSource: action.payload.data.data,
          fields: action.payload.data.fields,
          meta: action.payload.data.meta,
          linesOrder: action.payload.data.order_columns,
          linesTotal: linesComparative.filter((it) => it.type === ROWS.TOTALIZER),
          linesRatio: linesComparative.filter((it) => it.type === ROWS.RATIO),
          linesRatioN: linesComparative.filter((it) => it.type === ROWS.RATION),
        },
      })

    case types.FETCH_DATA_RDN_PERCENTAGE_BY_PLANNING_SUCCESS:
      return state.merge({
        dataRdnPercentagePlanning: {
          dataSource: action.payload.data.data,
          fields: action.payload.data.fields,
          meta: action.payload.data.meta,
        },
      })

    case types.SET_PERIOD_ORDER:
      return state.merge({
        periodList: action.payload,
      })

    default:
      return state
  }
}
